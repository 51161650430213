import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { SharedLayout } from "./components/Layout/SharedLayout";
import { Home } from "./pages/Home/Home";
import { Delivery } from "./pages/Delivery/Delivery";
import { Label } from "./pages/Label/Label";
import { Performer } from "./pages/Performer/Performer";
import { CassetteComp } from "./pages/Cassette/Cassette";
import { VinylComp } from "./pages/Vinyl/Vinyl";
import { MerchComp } from "./pages/Merch/Merch";
import { Release } from "./pages/Release/Release";
import { Admin } from "./pages/Admin/Admin";
import { AddGoods } from "./components/Admin/AddGoods";
import { AddArtist } from "./components/Admin/AddArtist";
import { AdminLabel } from "./components/Admin/AdminLabel";
import { AdminGoods } from "./components/Admin/AdminGoods";
import {
  getAllArtists,
  getAllCassettesAdmin,
  getAllMerchsAdmin,
  getAllVinylsAdmin,
} from "./services/axios";
import { Artist } from "./interfaces/artist";
import { Vinyl } from "./interfaces/vinyl";
import { Cassette } from "./interfaces/cassette";
import { Merch } from "./interfaces/merch";
import { ReleaseMerch } from "./pages/Release/ReleaseMerch";
import { Loader } from "./components/Loader/Loader";
import { Cart } from "./pages/Cart/Cart";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import "./18n.js";
import { Search } from "./pages/Search/Search";
import { HelmetProvider } from "react-helmet-async";
import { Order } from "./components/Admin/Order";

function App() {
  const [artists, setArtists] = useState<Artist[] | []>([]);
  const [vinyls, setVinyls] = useState<Vinyl[] | []>([]);
  const [cassettes, setCassettes] = useState<Cassette[] | []>([]);
  const [merchs, setMerchs] = useState<Merch[] | []>([]);

  const { i18n } = useTranslation();

  useEffect(() => {
    const clearCart = localStorage.getItem("clearCartOnce");
    if (clearCart) {
      return;
    } else {
      localStorage.removeItem("userCartIds");
      localStorage.removeItem("userCartTypes");
      localStorage.removeItem("userCartColors");
      localStorage.removeItem("userCartSizes");
      localStorage.removeItem("userCartQuantities");
      localStorage.removeItem("userCartLimits");
      localStorage.setItem("clearCartOnce", "true");
    }
  }, []);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    const fetchAllArtists = async () => {
      const data = await getAllArtists(i18n.language);
      setArtists(data);
    };

    const fetchAllVinyls = async () => {
      const data = await getAllVinylsAdmin(i18n.language);
      setVinyls(data);
    };

    const fetchAllCassettes = async () => {
      const data = await getAllCassettesAdmin(i18n.language);
      setCassettes(data);
    };

    const fetchAllMerchs = async () => {
      const data = await getAllMerchsAdmin(i18n.language);
      setMerchs(data);
    };

    fetchAllVinyls();
    fetchAllCassettes();
    fetchAllMerchs();
    fetchAllArtists();
  }, [i18n.language]);

  return (
    <Suspense fallback={<Loader />}>
      <HelmetProvider>
        <Routes>
          <Route
            path="/"
            element={<SharedLayout changeLanguage={changeLanguage} />}
          >
            <Route index element={<Home />} />
            <Route path="label" element={<Label />} />
            <Route path="vinyl" element={<VinylComp />} />
            <Route path="cassette" element={<CassetteComp />} />
            <Route path="merch" element={<MerchComp />} />
            {/* <Route path="blog" element={<Blog />} /> */}
            <Route path="cart" element={<Cart />} />
            <Route path="delivery" element={<Delivery />} />
            <Route path="performer/:id" element={<Performer />} />
            {vinyls?.map(({ _id }) => (
              <Route key={_id} path={`/release/:id`} element={<Release />} />
            ))}
            {cassettes?.map(({ _id }) => (
              <Route key={_id} path={`/release/:id`} element={<Release />} />
            ))}
            {merchs?.map(({ _id }) => (
              <Route
                key={_id}
                path={`/release/merch/:id`}
                element={<ReleaseMerch />}
              />
            ))}
            {artists?.map(({ _id }) => (
              <Route
                key={_id}
                path={`/performer/:id`}
                element={<Performer />}
              />
            ))}
            <Route path="search" element={<Search />} />
          </Route>
          <Route path="/admin" element={<Admin />}>
            <Route path="shop" element={<AdminGoods />} />
            <Route path="label" element={<AdminLabel />} />
            <Route path="addGoods" element={<AddGoods />} />
            <Route path="addArtist" element={<AddArtist />} />
            <Route path="orders" element={<Order />} />
          </Route>
        </Routes>
      </HelmetProvider>
      <ToastContainer autoClose={5000} />
    </Suspense>
  );
}

export default App;
