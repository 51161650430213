import { useEffect, useState } from "react";
import { Loader } from "../Loader/Loader";
import { checkStatus, sendReceipt } from "../../services/monobank";
import { ReactComponent as Success } from "../../assets/images/success.svg";
import { ReactComponent as Failed } from "../../assets/images/failed.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const Processing = ({
  setProcessing,
}: {
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [invoiceLink, setInvoiceLink] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [invoiceStatus, setInvoiceStatus] = useState("created");

  const { t } = useTranslation();

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const link = localStorage.getItem("invoiceLink");
    const id = localStorage.getItem("invoiceId");

    if (link) {
      setInvoiceLink(link);
    }

    if (id) {
      setInvoiceId(id);
    }

    const fetchStatus = async () => {
      const data = await checkStatus(id);
      if (data?.status === "success") {
        setInvoiceStatus(data?.status);
        localStorage.removeItem("invoiceLink");
        localStorage.removeItem("invoiceId");
        localStorage.removeItem("userCartColors");
        localStorage.removeItem("userCartId");
        localStorage.removeItem("userCartIds");
        localStorage.removeItem("userCartQuantities");
        localStorage.removeItem("userCartSizes");
        localStorage.removeItem("userCartTypes");
        clearInterval(intervalId);
      } else if (data?.status === "expired") {
        setInvoiceStatus(data?.status);
        localStorage.removeItem("invoiceLink");
        localStorage.removeItem("invoiceId");
        clearInterval(intervalId);
      }
    };

    intervalId = setInterval(fetchStatus, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const onGetReceiptButtonClick = async () => {
    await sendReceipt(invoiceId);
    toast("Чек було надіслано на пошту", {
      className: "toast__success",
      bodyClassName: "toast__body",
      progressClassName: "toast__progressBar--success",
    });
  };

  return (
    <div className="processing__backdrop">
      <div className="processing__modal">
        {invoiceStatus !== "success" && invoiceStatus !== "expired" && (
          <>
            <Loader />
            <p className="processing__title">
              {t("ProcessingTitleWaiting")}...
            </p>
            <a
              href={invoiceLink}
              target="_blank"
              rel="noreferrer"
              className="processing__button"
            >
              {t("ProcessingPaymentLinkText")}
            </a>
          </>
        )}
        {invoiceStatus === "success" && (
          <>
            <Success className="processing__icon" />
            <div>
              <p className="processing__status--text green">
                {t("ProcessingSuccessTitle")}
              </p>
              <p className="processing__status--text white">
                {t("ProcessingSuccessThanks")}
              </p>
            </div>
            <div className="processing__buttons--wrapper">
              <Link to="/" className="processing__button small">
                {t("ProcessingSuccessButtonFirst")}
              </Link>
              <button
                type="button"
                className="processing__button small"
                onClick={onGetReceiptButtonClick}
              >
                {t("ProcessingSuccessButtonSecond")}
              </button>
            </div>
          </>
        )}
        {invoiceStatus === "expired" && (
          <>
            <Failed className="processing__icon" />
            <div>
              <p className="processing__status--text red">
                {t("ProcessingErrorTitle")}
              </p>
              <p className="processing__status--text white">
                {t("ProcessingErrorTryAgain")}
              </p>
            </div>
            <div className="processing__buttons--wrapper">
              <Link
                to="/cart"
                className="processing__button small"
                onClick={() => {
                  setProcessing(false);
                }}
              >
                {t("ProcessingErrorButtonFirst")}
              </Link>
              <Link to="/" className="processing__button small">
                {t("ProcessingErrorButtonSecond")}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
