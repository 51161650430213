import { ReactComponent as GoodsList } from "../../assets/images/goodsList.svg";
import { ReactComponent as AddGoods } from "../../assets/images/addGoods.svg";
import { Link } from "react-router-dom";

interface LabelProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
}

export const ActionsLabel: React.FC<LabelProps> = ({
  language,
  setLanguage,
}) => {
  return (
    <div className="admin__actions">
      <div className="admin__action">
        <div className="admin__action--wrapper">
          <div className="admin__action--item">
            <p className="admin__action--text">Goods list</p>
            <GoodsList className="admin__action--svg1" />
          </div>
          <div className="admin__action--item">
            <Link to="/admin/addArtist" className="admin__action--text">
              Add Artist
            </Link>
            <AddGoods className="admin__action--svg" />
          </div>
        </div>
      </div>
      <div className="admin__action">
        <div className="admin__action--languages">
          <p
            className={
              language === "uk"
                ? "admin__action--text admin__action--textActive"
                : "admin__action--text"
            }
            onClick={() => {
              setLanguage("uk");
            }}
          >
            Ukrainian
          </p>
          <p
            className={
              language === "en"
                ? "admin__action--text admin__action--textActive"
                : "admin__action--text"
            }
            onClick={() => {
              setLanguage("en");
            }}
          >
            English
          </p>
        </div>
      </div>
    </div>
  );
};
