import { useTranslation } from "react-i18next";
import { About } from "../../components/About/About";
import { Hero } from "../../components/Hero/Hero";
import { SEO } from "../../components/SEO/SEO";
import { Title } from "../../components/Title/Title";

export const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t("HomePageTitleSEO")}
        description={t("HomePageDescriptionSEO")}
        link=""
        keywords="vinyl, store, вініл, винил, купить, придбати, records, label, indie, music"
        imageCard="https://dywlf.com/logo512.png"
      />
      <div className="home">
        <div className="container__two">
          <Title title="DO YOU WANNA LO-FI?" type={false} />
          <Hero />
          <About />
        </div>
      </div>
    </>
  );
};
