import { Title } from "../../components/Title/Title";
import { useEffect, useState } from "react";
import { Artist } from "../../interfaces/artist";
import { getAllArtists } from "../../services/axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SEO } from "../../components/SEO/SEO";
import { Loader } from "../../components/Loader/Loader";

export const Label = () => {
  const [artists, setArtists] = useState<Artist[] | []>([]);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchAllArtists = async () => {
      const data = await getAllArtists(i18n.language);
      setArtists(data);
    };

    fetchAllArtists();
  }, [i18n.language]);

  return (
    <>
      <Loader />
      {artists && (
        <>
          <SEO
            title={t("LabelPageTitleSEO")}
            description={t("LabelPageDescriptionSEO")}
            link="label"
            keywords="label, лейбл, украина, україна, ukraine, music, музыка, музика"
            imageCard="https://dywlf.com/logo512.png"
          />
          <section className="label">
            <div className="container__two">
              <Title title={t("LabelTitle")} type={false} />
              <ul className="label__list">
                {artists.map(({ _id, artistName, image }, index) => (
                  <Link
                    to={`/performer/${_id}`}
                    className="label__link"
                    key={_id}
                  >
                    <li className="label__item">
                      <div className="label__image--wrapper">
                        <img
                          src={image}
                          alt={artistName}
                          className="label__image"
                        />
                      </div>
                      <div className="label__name">{artistName}</div>
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </section>
        </>
      )}
    </>
  );
};
