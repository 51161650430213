import { ReactComponent as ArrowLeft } from "../../assets/images/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "../../assets/images/arrowRight.svg";

export const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) => {
  const pages = [];

  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      {totalPages === 1 ? (
        <></>
      ) : (
        <div className="pagination__wrapper">
          <button
            onClick={() => {
              if (currentPage === 1) {
                return;
              }
              handleScrollToTop();
              onPageChange(currentPage - 1);
            }}
            className={
              currentPage === 1
                ? "pagination__button pagination__button--disabled"
                : "pagination__button"
            }
          >
            <ArrowLeft />
          </button>
          {pages.map((page) => (
            <button
              key={page}
              onClick={() => {
                if (page !== currentPage) {
                  onPageChange(page);
                  handleScrollToTop();
                }
              }}
              className={
                page === currentPage
                  ? "pagination__number pagination__number--active"
                  : "pagination__number"
              }
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => {
              if (currentPage === totalPages) {
                return;
              }
              handleScrollToTop();
              onPageChange(currentPage + 1);
            }}
            className={
              currentPage === totalPages
                ? "pagination__button pagination__button--disabled"
                : "pagination__button"
            }
          >
            <ArrowRight />
          </button>
        </div>
      )}
    </>
  );
};
