import { ReactComponent as Vinyl } from "../../assets/images/vinylGoods.svg";
import { ReactComponent as Cassette } from "../../assets/images/cassetteGoods.svg";
import { ReactComponent as Merch } from "../../assets/images/merchGoods.svg";
import { ReactComponent as AddGoods } from "../../assets/images/addGoods.svg";
import { ReactComponent as ShowcaseList } from "../../assets/images/showcaseList.svg";
import { Link } from "react-router-dom";

interface ShopProps {
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  type: string;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

export const ActionsShop = ({
  language,
  setLanguage,
  type,
  setType,
}: ShopProps) => {
  return (
    <div className="admin__actions">
      <div className="admin__action">
        <div className="admin__action--wrapper">
          <div
            className={
              type === "vinyl"
                ? "admin__action--item admin__action--itemActive"
                : "admin__action--item"
            }
            onClick={() => {
              setType("vinyl");
            }}
          >
            <p className="admin__action--text">Vinyl</p>
            <Vinyl className="admin__action--svg" />
          </div>
          <div
            className={
              type === "cassette"
                ? "admin__action--item admin__action--itemActive"
                : "admin__action--item"
            }
            onClick={() => {
              setType("cassette");
            }}
          >
            <p className="admin__action--text">Cassette</p>
            <Cassette className="admin__action--svg" />
          </div>
          <div
            className={
              type === "merch"
                ? "admin__action--item admin__action--itemActive"
                : "admin__action--item"
            }
            onClick={() => {
              setType("merch");
            }}
          >
            <p className="admin__action--text">Merch</p>
            <Merch className="admin__action--svg" />
          </div>
          <div
            className={
              type === "showcase"
                ? "admin__action--item admin__action--itemActive"
                : "admin__action--item"
            }
            onClick={() => {
              setType("showcase");
            }}
          >
            <p className="admin__action--text">Showcase</p>
            <ShowcaseList className="admin__action--svg" />
          </div>
        </div>
        <div className="admin__action--item">
          <Link to="/admin/addGoods" className="admin__action--text">
            Add Goods
          </Link>
          <AddGoods className="admin__action--svg" />
        </div>
      </div>
      <div className="admin__action">
        <div className="admin__action--languages">
          <p
            className={
              language === "uk"
                ? "admin__action--text admin__action--textActive"
                : "admin__action--text"
            }
            onClick={() => {
              setLanguage("uk");
            }}
          >
            Ukrainian
          </p>
          <p
            className={
              language === "en"
                ? "admin__action--text admin__action--textActive"
                : "admin__action--text"
            }
            onClick={() => {
              setLanguage("en");
            }}
          >
            English
          </p>
        </div>
      </div>
    </div>
  );
};
