import { useRef, useState } from "react";
import { ReactComponent as Type1 } from "../../assets/images/type1.svg";
import { ReactComponent as Type2 } from "../../assets/images/type2.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const Title = ({
  title,
  type,
  direction,
  setDirection,
}: {
  title: string;
  type: boolean;
  direction?: boolean;
  setDirection?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [hint, setHint] = useState("");
  const inputRef = useRef(null);

  const { i18n, t } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const fetchSuggestions = async (input: string) => {
    try {
      const { data } = await axios.get(
        `https://dywlf.com/api/search/result?query=${input}&language=${i18n.language}`
      );

      if (data.length > 0) {
        const firstSuggestion =
          data[0].artistName || data[0].albumName || data[0].name;
        if (firstSuggestion.toLowerCase().startsWith(input.toLowerCase())) {
          setHint(firstSuggestion);
        } else {
          setHint("");
        }
        setSuggestions(data);
      } else {
        setHint("");
      }
    } catch (error) {}
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setQuery(input);

    if (input.length > 1) {
      fetchSuggestions(input);
    } else {
      setSuggestions([]);
      setHint("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      localStorage.setItem("searchSuggestions", JSON.stringify(suggestions));
      localStorage.setItem("searchQuery", query);
      window.location.href = "/search";
    }

    if (hint && (e.key === "Tab" || e.key === "ArrowRight")) {
      e.preventDefault();
      setQuery(hint);
      setHint("");
    }
  };

  const onSearchInputFocus = () => {
    setSearching(true);
  };

  const onSearchInputBlur = () => {
    setSearching(false);
    setHint("");
    setQuery("");
  };
  return (
    <div
      className={
        searching
          ? type
            ? "title title__searching title__typing"
            : "title title__searching"
          : type
          ? "title title__typing"
          : "title"
      }
    >
      <h1
        className={
          !searching
            ? title === "DO YOU WANNA LO-FI?"
              ? "title__text title__text--appearing "
              : "title__text"
            : "title__text--hide"
        }
      >
        {title}
      </h1>
      {type && (
        <div className="title__types--wrapper">
          <div
            className="title__type"
            style={
              direction
                ? {
                    backgroundColor: "#251F1E80",
                  }
                : { backgroundColor: "transparent" }
            }
            onClick={() => {
              if (setDirection) {
                setDirection((prev) => !prev);
              }
            }}
          >
            <Type1 />
          </div>
          <div
            className="title__type"
            onClick={() => {
              if (setDirection) {
                setDirection((prev) => !prev);
              }
            }}
            style={
              direction
                ? { backgroundColor: "transparent" }
                : {
                    backgroundColor: "#251F1E80",
                  }
            }
          >
            <Type2 />
          </div>
        </div>
      )}
      <div
        className={searching ? "title__wrapper width100" : "title__wrapper out"}
      >
        {!searching && (
          <div
            onClick={() => {
              changeLanguage(i18n.language === "uk" ? "en" : "uk");
            }}
            className="title__language"
          >
            {i18n.language === "uk" ? "ua" : "en"}
          </div>
        )}
        <input
          type="text"
          placeholder={t("TitleSearch")}
          value={query}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className={
            searching
              ? "title__input title__input--active width100 "
              : "title__input "
          }
          onFocus={onSearchInputFocus}
          onBlur={onSearchInputBlur}
        />
        {searching && (
          <div className="title__input--text">
            <span className="title__input--textActive">{query}</span>
            {hint.slice(query.length)}
          </div>
        )}
      </div>
    </div>
  );
};
