import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Cassette } from "../../interfaces/cassette";
import { Vinyl } from "../../interfaces/vinyl";
import { getCassette, getUsdExchange, getVinyl } from "../../services/axios";
import { useTranslation } from "react-i18next";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";

export const Recommendation = ({
  recommendationList,
}: {
  recommendationList: string[];
}) => {
  const [data, setData] = useState<Vinyl[] | Cassette[] | []>([]);
  const [exchangeRate, setExchangeRate] = useState(41);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchRecommendationItems = async () => {
      const recommendations = [];
      for (const item of recommendationList) {
        const vinyl = await getVinyl(item, i18n.language);

        if (vinyl) {
          recommendations.push(vinyl);
        } else {
          const cassette = await getCassette(item, i18n.language);
          if (cassette) {
            recommendations.push(cassette);
          }
        }
      }

      setData(recommendations);
    };

    fetchRecommendationItems();
  }, [recommendationList, i18n.language]);

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  const addToCart = (index: number) => {
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data[index]?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem(
        "userCartTypes",
        JSON.stringify([data[index]?.type])
      );
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push("");
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([""]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push("");
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([""]));
    }

    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
  };

  const onButtonBuyClick = (_id: string, index: number) => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      if (ids.includes(_id)) {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 500);
      } else {
        ids.push(_id);
        localStorage.setItem("userCartIds", JSON.stringify(ids));
        addToCart(index);
      }
    } else {
      localStorage.setItem("userCartIds", JSON.stringify([_id]));
      addToCart(index);
    }

    setTimeout(() => {
      window.location.href = "/cart";
    }, 500);
  };

  return (
    <div className="recommendations__list">
      {data.map(
        (
          {
            _id,
            coverPicture,
            availability,
            artistName,
            albumName,
            uah,
            usd,
            uahSale,
            usdSale,
          },
          index
        ) => (
          <div className="recommendations__item" key={_id}>
            <Link to={`/release/${_id}`}>
              <img
                src={coverPicture}
                alt={artistName}
                className="recommendations__image"
              />
            </Link>
            <p className="recommendations__author">{artistName}</p>
            <p className="recommendations__name">{albumName}</p>{" "}
            <div className="productsList__price--wrapper">
              {" "}
              {i18n.language === "uk" && (uahSale !== 0 || usdSale !== 0) && (
                <p className="productsList__price--sale">
                  <s>
                    {uahSale === 0
                      ? usdSale && roundToHigherOrder(exchangeRate * usdSale)
                      : uahSale}
                    {t("Currency")}
                  </s>
                </p>
              )}
              {i18n.language === "en" && usdSale !== 0 && (
                <p className="productsList__price--sale">
                  <s>
                    {usdSale} {t("Currency")}
                  </s>
                </p>
              )}
              <p className="productsList__price">
                {i18n.language === "en"
                  ? usd
                  : uah === 0
                  ? roundToHigherOrder(usd * exchangeRate)
                  : uah}{" "}
                {t("Currency")}
              </p>
            </div>
            <button
              type="button"
              className={
                availability === "outOfStock"
                  ? "productsList__button button__outOfStock"
                  : "productsList__button button__hovering"
              }
              onClick={() => {
                if (availability === "outOfStock") {
                  return;
                }
                onButtonBuyClick(_id, index);
              }}
            >
              {availability === "inStock"
                ? t("ButtonAvailabilityInStock")
                : availability === "preOrder"
                ? t("ButtonAvailabilityPreOrder")
                : t("ButtonAvailabilityOutOfStock")}{" "}
            </button>
          </div>
        )
      )}
    </div>
  );
};
