import { useTranslation } from "react-i18next";
import { Title } from "../../components/Title/Title";
import { useEffect, useState } from "react";
import { getUsdExchange } from "../../services/axios";
import { Link } from "react-router-dom";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { Info } from "../../interfaces/merch";
import noResults from "../../assets/images/emptyPage.png";
import { SEO } from "../../components/SEO/SEO";

export const Search = () => {
  const [exchangeRate, setExchangeRate] = useState(41);
  const [data, setData] = useState<any>([]);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };
    const suggestion = localStorage.getItem("searchSuggestions");
    if (suggestion) {
      setData(JSON.parse(suggestion));
    }

    fetchExchanceRate();
  }, []);

  const addToCart = (index: number) => {
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data[index]?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem(
        "userCartTypes",
        JSON.stringify([data[index]?.type])
      );
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push("");
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([""]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push("");
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([""]));
    }

    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
  };

  const onButtonBuyClick = (_id: string, index: number) => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      if (ids.includes(_id)) {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 500);
      } else {
        ids.push(_id);
        localStorage.setItem("userCartIds", JSON.stringify(ids));
        addToCart(index);
      }
    } else {
      localStorage.setItem("userCartIds", JSON.stringify([_id]));
      addToCart(index);
    }

    setTimeout(() => {
      window.location.href = "/cart";
    }, 500);
  };

  return (
    <>
      <SEO title={t("SearchPageTitleSEO")} noIndex />
      <section className="search">
        <div className="container__two">
          <Title
            title={
              t("SearchResult") + ` ${localStorage.getItem("searchQuery")}`
            }
            type={false}
          />
          {data.length > 0 ? (
            <ul className={"productsList__list"}>
              {data?.map(
                (
                  {
                    _id,
                    type,
                    info,
                    name,
                    from,
                    availability,
                    coverPicture,
                    artistName,
                    albumName,
                    uah,
                    uahSale,
                    usd,
                    usdSale,
                  }: {
                    _id: string;
                    type: string;
                    info: Info[];
                    name: string;
                    from: string;
                    availability: string;
                    coverPicture: string;
                    artistName: string;
                    albumName: string;
                    uah: number;
                    uahSale?: number;
                    usd: number;
                    usdSale?: number;
                  },
                  index: number
                ) => (
                  <li
                    key={index}
                    className={"productsList__item productsList__item--type1"}
                  >
                    <Link
                      to={
                        type === "merch"
                          ? `/release/merch/${_id}`
                          : `/release/${_id}`
                      }
                    >
                      <img
                        src={type === "merch" ? info[0]?.image : coverPicture}
                        alt={type === "merch" ? name : artistName}
                        className="productsList__image"
                      />
                    </Link>
                    <div className="productsList__thumb">
                      <p
                        className="productsList__author"
                        style={{ textAlign: "center" }}
                      >
                        {type === "merch" ? name : artistName}
                      </p>
                      <p
                        className="productsList__name"
                        style={{ textAlign: "center" }}
                      >
                        {type === "merch" ? from : albumName}
                      </p>
                      <div
                        className="productsList__price--wrapper"
                        style={{ justifyContent: "center" }}
                      >
                        {i18n.language === "uk" &&
                          (uahSale !== 0 || usdSale !== 0) && (
                            <p
                              className="productsList__price--sale"
                              style={{ textAlign: "center" }}
                            >
                              <s>
                                {uahSale === 0
                                  ? usdSale &&
                                    roundToHigherOrder(exchangeRate * usdSale)
                                  : uahSale}
                                {t("Currency")}
                              </s>
                            </p>
                          )}
                        {i18n.language === "en" && usdSale !== 0 && (
                          <p
                            className="productsList__price--sale"
                            style={{ textAlign: "center" }}
                          >
                            <s>
                              {usdSale} {t("Currency")}
                            </s>
                          </p>
                        )}
                        <p
                          className="productsList__price"
                          style={{ textAlign: "center" }}
                        >
                          {i18n.language === "en"
                            ? usd
                            : uah === 0
                            ? roundToHigherOrder(usd * exchangeRate)
                            : uah}{" "}
                          {t("Currency")}
                        </p>
                      </div>
                      <button
                        type="button"
                        className={
                          availability === "outOfStock"
                            ? "productsList__button button__outOfStock"
                            : "productsList__button button__hovering"
                        }
                        onClick={() => {
                          if (availability === "outOfStock") {
                            return;
                          }
                          onButtonBuyClick(_id, index);
                        }}
                      >
                        {availability === "inStock"
                          ? t("ButtonAvailabilityInStock")
                          : availability === "preOrder"
                          ? t("ButtonAvailabilityPreOrder")
                          : t("ButtonAvailabilityOutOfStock")}{" "}
                      </button>
                    </div>
                  </li>
                )
              )}
            </ul>
          ) : (
            <>
              <p className="search__noResults">{t("SearchNoResults")} :(</p>
              <img
                src={noResults}
                alt="no results"
                className="search__noResults--image"
              />
            </>
          )}
        </div>
      </section>
    </>
  );
};
