import { Link } from "react-router-dom";
import { ReactComponent as AdminBack } from "../../assets/images/adminBack.svg";
import { useState } from "react";
import { ReactComponent as CoverImg } from "../../assets/images/coverImg.svg";
import { ReactComponent as VinylCoverImg } from "../../assets/images/vinylCoverImg.svg";
import { ReactComponent as CassetteCoverImg } from "../../assets/images/cassetteCoverImg.svg";
import { ReactComponent as OtherImg } from "../../assets/images/otherImg.svg";
import { ReactComponent as AddColor } from "../../assets/images/addGoods.svg";
import {
  createCassette,
  createMerch,
  createVinyl,
  deleteImage,
  getAllShowcases,
  uploadImage,
} from "../../services/axios";
import { ReactComponent as AdminBin } from "../../assets/images/adminBin.svg";
import { getLastImagePart } from "../../utils/getLastImagePart";
import { SEO } from "../SEO/SEO";

export const AddGoods = () => {
  const [type, setType] = useState("vinyl");
  const [availability, setAvailability] = useState("inStock");
  const [amount, setAmount] = useState(0);
  const [priority, setPriority] = useState(1);
  const [showcase, setShowcase] = useState(false);
  const [showcaseImage, setShowcaseImage] = useState("");
  const [artistName, setArtistName] = useState("");
  const [albumName, setAlbumName] = useState("");
  const [name, setName] = useState("");
  const [from, setFrom] = useState("");
  const [labelName, setLabelName] = useState("");
  const [issue, setIssue] = useState("");
  const [country, setCountry] = useState("");
  const [genre, setGenre] = useState("");
  const [uah, setUah] = useState<string>("");
  const [usd, setUsd] = useState<string>("");
  const [uahSale, setUahSale] = useState<string>("");
  const [usdSale, setUsdSale] = useState<string>("");
  const [tracklist, setTracklist] = useState("");
  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [link3, setLink3] = useState("");
  const [tags, setTags] = useState("");
  const [sale, setSale] = useState(false);
  const [info, setInfo] = useState([
    {
      xl: 0,
      l: 0,
      m: 0,
      s: 0,
      xs: 0,
      color: "",
      image: "",
      oneSizeAmount: 0,
    },
  ]);
  const [infos, setInfos] = useState([1]);
  const [oneSize, setOneSize] = useState(false);
  const [oneColor, setOneColor] = useState(false);
  const [description, setDescription] = useState("");
  const [coverPicture, setCoverPicture] = useState("");
  const [otherPicture1, setOtherPicture1] = useState("");
  const [otherPicture2, setOtherPicture2] = useState("");
  const [otherPicture3, setOtherPicture3] = useState("");
  const [otherPicture4, setOtherPicture4] = useState("");
  const [oneImage, setOneImage] = useState("");
  const [artistNameError, setArtistNameError] = useState(false);
  const [albumNameError, setAlbumNameError] = useState(false);
  const [coverPictureError, setCoverPictureError] = useState(false);
  const [labelNameError, setLabelNameError] = useState(false);
  const [issueError, setIssueError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [genreError, setGenreError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [fromError, setFromError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const [uahError, setUahError] = useState(false);
  const [usdError, setUsdError] = useState(false);
  const [uahSaleError, setUahSaleError] = useState(false);
  const [usdSaleError, setUsdSaleError] = useState(false);

  const onDeleteImageClick = async (url: string) => {
    deleteImage(getLastImagePart(url));
  };

  const onSubmitButtonClick = async () => {
    let amountMerch = 0;

    for (const size of info) {
      amountMerch += size.xl;
      amountMerch += size.l;
      amountMerch += size.m;
      amountMerch += size.s;
      amountMerch += size.xs;
      amountMerch += size.oneSizeAmount;
    }

    const otherPictures: string[] = [];
    [otherPicture1, otherPicture2, otherPicture3, otherPicture4].forEach(
      (picture) => {
        if (picture !== "") {
          otherPictures.push(picture);
        }
      }
    );

    const links: string[] = [];
    [link1, link2, link3].forEach((link) => {
      if (link !== "") {
        links.push(link);
      }
    });
    const data = await getAllShowcases("uk");

    const uahNumber = Number(uah);
    const usdNumber = Number(usd);
    const uahSaleNumber = Number(uahSale);
    const usdSaleNumber = Number(usdSale);

    if (isNaN(uahNumber) || uahNumber < 0) {
      setUahError(true);
    } else {
      setUahError(false);
    }

    if (isNaN(usdNumber) || usdNumber < 0) {
      setUsdError(true);
    } else {
      setUsdError(false);
    }

    if (isNaN(uahSaleNumber) || uahSaleNumber < 0) {
      setUahSaleError(true);
    } else {
      setUahSaleError(false);
    }

    if (isNaN(usdSaleNumber) || usdSaleNumber < 0) {
      setUsdSaleError(true);
    } else {
      setUsdSaleError(false);
    }

    if (type === "vinyl") {
      if (
        artistName &&
        albumName &&
        coverPicture &&
        labelName &&
        issue &&
        country &&
        genre &&
        tracklist &&
        !usdError &&
        !uahError &&
        !usdSaleError &&
        !uahSaleError
      ) {
        createVinyl({
          type,
          availability,
          amount,
          priority,
          showcaseImage,
          artistName,
          albumName,
          coverPicture,
          otherPictures,
          labelName,
          issue,
          country,
          genre,
          uah: uahNumber,
          usd: usdNumber,
          uahSale: uahSaleNumber,
          usdSale: usdSaleNumber,
          tracklist,
          links,
          tags,
          showcase,
          order: showcase ? (data ? data.length + 1 : 1) : 0,
          archived: false,
          language: "uk",
          adapted: true,
        });

        createVinyl({
          type,
          availability,
          amount,
          priority,
          showcaseImage,
          artistName,
          albumName,
          coverPicture,
          otherPictures,
          labelName,
          issue,
          country,
          genre,
          uah: uahNumber,
          usd: usdNumber,
          uahSale: uahSaleNumber,
          usdSale: usdSaleNumber,
          tracklist,
          links,
          tags,
          showcase,
          order: showcase ? (data ? data.length + 1 : 1) : 0,
          archived: false,
          language: "en",
          adapted: false,
        });
        setTimeout(() => {
          window.location.href = "/admin/shop";
        }, 500);
      } else {
        if (!artistName) {
          setArtistNameError(true);
        } else {
          setArtistNameError(false);
        }

        if (!albumName) {
          setAlbumNameError(true);
        } else {
          setAlbumNameError(false);
        }

        if (!coverPicture) {
          setCoverPictureError(true);
        } else {
          setCoverPictureError(false);
        }

        if (!labelName) {
          setLabelNameError(true);
        } else {
          setLabelNameError(false);
        }

        if (!issue) {
          setIssueError(true);
        } else {
          setIssueError(false);
        }

        if (!country) {
          setCountryError(true);
        } else {
          setCountryError(false);
        }

        if (!genre) {
          setGenreError(true);
        } else {
          setGenreError(false);
        }
      }
    } else if (type === "cassette") {
      if (
        artistName &&
        albumName &&
        coverPicture &&
        labelName &&
        issue &&
        country &&
        genre &&
        tracklist &&
        !usdError &&
        !uahError &&
        !usdSaleError &&
        !uahSaleError
      ) {
        createCassette({
          type,
          availability,
          amount,
          priority,
          showcaseImage,
          artistName,
          albumName,
          coverPicture,
          otherPictures,
          labelName,
          issue,
          country,
          genre,
          uah: uahNumber,
          usd: usdNumber,
          uahSale: uahSaleNumber,
          usdSale: usdSaleNumber,
          tracklist,
          links,
          tags,
          showcase,
          order: showcase ? (data ? data.length + 1 : 1) : 0,
          archived: false,
          language: "uk",
          adapted: true,
        });
        createCassette({
          type,
          availability,
          amount,
          priority,
          showcaseImage,
          artistName,
          albumName,
          coverPicture,
          otherPictures,
          labelName,
          issue,
          country,
          genre,
          uah: uahNumber,
          usd: usdNumber,
          uahSale: uahSaleNumber,
          usdSale: usdSaleNumber,
          tracklist,
          links,
          tags,
          showcase,
          order: showcase ? (data ? data.length + 1 : 1) : 0,
          archived: false,
          language: "en",
          adapted: false,
        });
        setTimeout(() => {
          window.location.href = "/admin/shop";
        }, 500);
      } else {
        if (!artistName) {
          setArtistNameError(true);
        } else {
          setArtistNameError(false);
        }

        if (!albumName) {
          setAlbumNameError(true);
        } else {
          setAlbumNameError(false);
        }

        if (!coverPicture) {
          setCoverPictureError(true);
        } else {
          setCoverPictureError(false);
        }

        if (!labelName) {
          setLabelNameError(true);
        } else {
          setLabelNameError(false);
        }

        if (!issue) {
          setIssueError(true);
        } else {
          setIssueError(false);
        }

        if (!country) {
          setCountryError(true);
        } else {
          setCountryError(false);
        }

        if (!genre) {
          setGenreError(true);
        } else {
          setGenreError(false);
        }
      }
    } else if (type === "merch") {
      if (name && from && description) {
        createMerch({
          type,
          name,
          from,
          availability,
          amount: amountMerch,
          info,
          oneColor,
          oneImage,
          description,
          uah: uahNumber,
          usd: usdNumber,
          uahSale: uahSaleNumber,
          usdSale: usdSaleNumber,
          links,
          tags,
          archived: false,
          language: "uk",
          adapted: true,
        });
        createMerch({
          type,
          name,
          from,
          availability,
          amount: amount,
          info,
          oneColor,
          oneImage,
          description,
          uah: uahNumber,
          usd: usdNumber,
          uahSale: uahSaleNumber,
          usdSale: usdSaleNumber,
          links,
          tags,
          archived: false,
          language: "en",
          adapted: false,
        });

        setTimeout(() => {
          window.location.href = "/admin/shop";
        }, 500);
      } else {
        if (!name) {
          setNameError(true);
        } else {
          setNameError(false);
        }

        if (!from) {
          setFromError(true);
        } else {
          setFromError(false);
        }

        if (!description) {
          setDescriptionError(true);
        } else {
          setDescriptionError(false);
        }
      }
    }
  };

  const onAddColorButtonClick = async () => {
    setInfo((prev) => [
      ...prev,
      {
        xl: 0,
        l: 0,
        m: 0,
        s: 0,
        xs: 0,
        color: "",
        image: "",
        oneSizeAmount: 0,
      },
    ]);

    setInfos((prev) => [...prev, 1]);
  };

  const onDeleteColorButtonClick = async (index: number) => {
    setInfo((prev) => prev.filter((_, i) => i !== index));
    setInfos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <SEO title="Додавання товару" noIndex />
      <div className="admin__addGoods">
        <div className="admin__addGoods--wrapper">
          <Link to="/admin/shop" className="admin__back--link">
            <AdminBack />
          </Link>
          <div className="admin__addGoods--title">Add Goods</div>
        </div>
        <p className="admin__addGoods--label">Type</p>
        <div className="admin__addGoods--inputWrapper">
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: type === "vinyl" ? "#ffffff" : "" }}
            onClick={() => {
              setType("vinyl");
            }}
          >
            <div
              className={
                type === "vinyl"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Vinyl
          </div>
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: type === "cassette" ? "#ffffff" : "" }}
            onClick={() => {
              setType("cassette");
            }}
          >
            <div
              className={
                type === "cassette"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Casette
          </div>
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: type === "merch" ? "#ffffff" : "" }}
            onClick={() => {
              setType("merch");
            }}
          >
            <div
              className={
                type === "merch"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Merch
          </div>
        </div>
        {type === "merch" && (
          <>
            <p className="admin__addGoods--label">Main info</p>
            <div className="admin__addGoods--inputWrapper">
              <input
                type="text"
                className={
                  nameError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name"
              />
              <input
                type="text"
                className={
                  fromError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={from}
                onChange={(e) => {
                  setFrom(e.target.value);
                }}
                placeholder="From"
              />
            </div>
          </>
        )}
        <p className="admin__addGoods--label">Availability</p>
        <div className="admin__addGoods--inputWrapper">
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: availability === "inStock" ? "#ffffff" : "" }}
            onClick={() => {
              setAvailability("inStock");
            }}
          >
            <div
              className={
                availability === "inStock"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            In stock
          </div>
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: availability === "preOrder" ? "#ffffff" : "" }}
            onClick={() => {
              setAvailability("preOrder");
            }}
          >
            <div
              className={
                availability === "preOrder"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Pre-order
          </div>
          <div
            className="admin__addGoods--input admin__addGoods--radio"
            style={{ color: availability === "outOfStock" ? "#ffffff" : "" }}
            onClick={() => {
              setAvailability("outOfStock");
            }}
          >
            <div
              className={
                availability === "outOfStock"
                  ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                  : "admin__addGoods--radioSquare"
              }
            ></div>
            Out of Stock
          </div>
        </div>
        {availability === "inStock" && type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Amount</p>
            <div className="admin__addGoods--inputWrapper">
              <input
                type="number"
                className="admin__addGoods--input"
                style={{ maxWidth: "245px" }}
                value={amount}
                onChange={(e) => {
                  setAmount(+e.target.value);
                }}
              />
            </div>
          </>
        )}
        {type === "merch" && availability === "inStock" && (
          <>
            <p className="admin__addGoods--label">Sizes</p>
            <div className="admin__addGoods--inputWrapper">
              <div
                className="admin__addGoods--input admin__addGoods--radio"
                style={{ color: oneSize ? "#ffffff" : "" }}
                onClick={() => {
                  setOneSize((prev) => !prev);
                }}
              >
                <div
                  className={
                    oneSize
                      ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                      : "admin__addGoods--radioSquare"
                  }
                ></div>
                One-size
              </div>
            </div>
          </>
        )}
        {type === "merch" && (
          <>
            <p className="admin__addGoods--label">Colors</p>
            <div className="admin__addGoods--inputWrapper">
              <div
                className="admin__addGoods--input admin__addGoods--radio"
                style={{ color: oneColor ? "#ffffff" : "" }}
                onClick={() => {
                  setOneColor((prev) => !prev);
                }}
              >
                <div
                  className={
                    oneColor
                      ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                      : "admin__addGoods--radioSquare"
                  }
                ></div>
                One-color
              </div>
            </div>
            {infos.map((_, index) => (
              <div key={index}>
                <div className="admin__addGoods--smallLabelWrapper">
                  <p className="admin__addGoods--smallLabel">
                    Color {index + 1}
                  </p>
                  <svg
                    onClick={() => {
                      onDeleteColorButtonClick(index);
                    }}
                    style={{ cursor: "pointer" }}
                    width="19"
                    height="24"
                    viewBox="0 0 19 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 1.33333H14.3333L13 0H6.33331L4.99998 1.33333H0.333313V4H19M1.66665 21.3333C1.66665 22.0406 1.9476 22.7189 2.44769 23.219C2.94779 23.719 3.62607 24 4.33331 24H15C15.7072 24 16.3855 23.719 16.8856 23.219C17.3857 22.7189 17.6666 22.0406 17.6666 21.3333V5.33333H1.66665V21.3333Z"
                      fill="#5B4E4C"
                    />
                  </svg>
                </div>
                <div className="admin__addGoods--fileWrapper">
                  <div className="admin__addGoods--fileThumb dashedBorder">
                    <input
                      type="file"
                      className="admin__addGoods--inputFile"
                      onChange={async (
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (e.target.files) {
                          const url = await uploadImage(e.target.files[0]);
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].image = url;
                            return arr;
                          });
                          if (index === 0) {
                            setOneImage(url);
                          }
                          setInfos((prev) => [...prev, 1]);
                          setInfos((prev) => {
                            const arr = [...prev];
                            arr.pop();
                            return arr;
                          });
                        }
                      }}
                    />
                    {info[index].image && (
                      <AdminBin
                        className="admin__image--bin"
                        onClick={() => {
                          onDeleteImageClick(info[index].image);
                          if (index === 0) {
                            setOneImage("");
                          }
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].image = "";
                            return arr;
                          });
                        }}
                      />
                    )}
                    {!info[index].image && (
                      <OtherImg className="admin__addGoods--merchCoverImg" />
                    )}
                    {info[index].image && (
                      <img
                        src={info[index].image}
                        alt="merch"
                        className="admin__addGoods--img"
                      />
                    )}
                  </div>
                </div>
                <div
                  className="admin__addGoods--inputWrapper"
                  style={{ position: "relative", marginTop: "10px" }}
                >
                  <input
                    style={{ paddingLeft: "44px" }}
                    className="admin__addGoods--input"
                    placeholder="#000000"
                    value={info[index].color}
                    onChange={(e) => {
                      setInfo((prev) => {
                        const arr = [...prev];
                        arr[index].color = e.target.value;
                        return arr;
                      });
                    }}
                  />
                  <div
                    className="admin__addGoods--radioSquare admin__addGoods--radioChoosen admin__addGoods--merchSquare"
                    style={{
                      background:
                        info[index].color.length >= 4 ? info[index].color : "",
                    }}
                  ></div>
                  {!oneSize ? (
                    <>
                      <p className="admin__addGoods--label">XL</p>
                      <input
                        type="number"
                        className="admin__addGoods--input"
                        style={{ maxWidth: "85px" }}
                        value={info[index].xl}
                        onChange={(e) => {
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].xl = +e.target.value;
                            return arr;
                          });
                        }}
                      />
                      <p className="admin__addGoods--label">L</p>
                      <input
                        type="number"
                        className="admin__addGoods--input"
                        style={{ maxWidth: "85px" }}
                        value={info[index].l}
                        onChange={(e) => {
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].l = +e.target.value;
                            return arr;
                          });
                        }}
                      />
                      <p className="admin__addGoods--label">M</p>
                      <input
                        type="number"
                        className="admin__addGoods--input"
                        style={{ maxWidth: "85px" }}
                        value={info[index].m}
                        onChange={(e) => {
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].m = +e.target.value;
                            return arr;
                          });
                        }}
                      />
                      <p className="admin__addGoods--label">S</p>
                      <input
                        type="number"
                        className="admin__addGoods--input"
                        style={{ maxWidth: "85px" }}
                        value={info[index].s}
                        onChange={(e) => {
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].s = +e.target.value;
                            return arr;
                          });
                        }}
                      />
                      <p className="admin__addGoods--label">XS</p>
                      <input
                        type="number"
                        className="admin__addGoods--input"
                        style={{ maxWidth: "85px" }}
                        value={info[index].xs}
                        onChange={(e) => {
                          setInfo((prev) => {
                            const arr = [...prev];
                            arr[index].xs = +e.target.value;
                            return arr;
                          });
                        }}
                      />
                    </>
                  ) : (
                    <input
                      type="number"
                      className="admin__addGoods--input"
                      style={{ maxWidth: "245px", marginTop: "10px;" }}
                      value={info[index].oneSizeAmount}
                      onChange={(e) => {
                        setInfo((prev) => {
                          const arr = [...prev];
                          arr[index].oneSizeAmount = +e.target.value;
                          return arr;
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
            {!oneColor && (
              <button
                type="button"
                className="admin__addGoods--colorBtn"
                onClick={() => {
                  onAddColorButtonClick();
                }}
              >
                Add color <AddColor className="admin__addGoods--colorBtnSvg" />
              </button>
            )}
            <p className="admin__addGoods--label">Description</p>
            <p className="admin__addGoods--smallLabel">Max 120 symbols</p>
            <div className="admin__addGoods--inputWrapper">
              <input
                type="text"
                className={
                  descriptionError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                placeholder="Description"
              />
            </div>
          </>
        )}
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Priority</p>
            <div className="admin__addGoods--inputWrapper">
              <div
                className="admin__addGoods--input admin__addGoods--radio"
                style={{ color: priority === 1 ? "#ffffff" : "" }}
                onClick={() => {
                  setPriority(1);
                }}
              >
                <div
                  className={
                    priority === 1
                      ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                      : "admin__addGoods--radioSquare"
                  }
                ></div>
                1
              </div>
              <div
                className="admin__addGoods--input admin__addGoods--radio"
                style={{ color: priority === 2 ? "#ffffff" : "" }}
                onClick={() => {
                  setPriority(2);
                }}
              >
                <div
                  className={
                    priority === 2
                      ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                      : "admin__addGoods--radioSquare"
                  }
                ></div>
                2
              </div>
            </div>
          </>
        )}
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Showcase</p>
            <div
              className="admin__addGoods--input admin__addGoods--radio"
              style={{ color: showcase ? "#ffffff" : "" }}
              onClick={() => {
                setShowcase((prev) => !prev);
              }}
            >
              <div
                className={
                  showcase
                    ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                    : "admin__addGoods--radioSquare"
                }
              ></div>
              Add to the showcase
            </div>
          </>
        )}
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Main info</p>
            <div className="admin__addGoods--inputWrapper">
              <input
                type="text"
                className={
                  artistNameError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={artistName}
                onChange={(e) => {
                  setArtistName(e.target.value);
                }}
                placeholder="Artist Name"
              />
              <input
                type="text"
                className={
                  albumNameError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={albumName}
                onChange={(e) => {
                  setAlbumName(e.target.value);
                }}
                placeholder="Album Name"
              />
            </div>
          </>
        )}
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Cover Picture</p>
            <div className="admin__addGoods--fileWrapper">
              <div
                className={
                  coverPictureError
                    ? "admin__addGoods--fileThumb dashedBorderRed"
                    : "admin__addGoods--fileThumb dashedBorder"
                }
              >
                <input
                  type="file"
                  className="admin__addGoods--inputFile"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      const url = await uploadImage(e.target.files[0]);
                      setCoverPicture(url);
                    }
                  }}
                />
                {coverPicture && (
                  <AdminBin
                    className="admin__image--bin"
                    onClick={() => {
                      onDeleteImageClick(coverPicture);
                      setCoverPicture("");
                    }}
                  />
                )}
                {!coverPicture && (
                  <CoverImg className="admin__addGoods--coverImg" />
                )}
                {coverPicture && (
                  <img
                    src={coverPicture}
                    alt="cover good"
                    className="admin__addGoods--img"
                  />
                )}
              </div>
            </div>
          </>
        )}
        {showcase && type !== "merch" && (
          <>
            <p className="admin__addGoods--label">
              {type === "vinyl" ? "Vinyl" : "Cassette"} picture
            </p>
            <div className="admin__addGoods--fileWrapper ">
              <div className="admin__addGoods--fileThumb dashedBorder">
                <input
                  type="file"
                  className="admin__addGoods--inputFile"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      const url = await uploadImage(e.target.files[0]);
                      setShowcaseImage(url);
                    }
                  }}
                />
                {showcaseImage && (
                  <AdminBin
                    className="admin__image--bin"
                    onClick={() => {
                      onDeleteImageClick(showcaseImage);
                      setShowcaseImage("");
                    }}
                  />
                )}
                {type === "vinyl" ? (
                  <>
                    {!showcaseImage && (
                      <VinylCoverImg className="admin__addGoods--coverImg" />
                    )}
                  </>
                ) : (
                  <>
                    {!showcaseImage && (
                      <CassetteCoverImg className="admin__addGoods--coverImg" />
                    )}
                  </>
                )}
                {showcaseImage && (
                  <img
                    src={showcaseImage}
                    alt="cover good"
                    className="admin__addGoods--img"
                  />
                )}
              </div>
            </div>
          </>
        )}
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Other Images</p>
            <div className="admin__addGoods--fileWrapper">
              <div className="admin__addGoods--fileThumb dashedBorder">
                <input
                  type="file"
                  className="admin__addGoods--inputFile"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      const url = await uploadImage(e.target.files[0]);
                      setOtherPicture1(url);
                    }
                  }}
                />
                {otherPicture1 && (
                  <AdminBin
                    className="admin__image--bin"
                    onClick={() => {
                      onDeleteImageClick(otherPicture1);
                      setOtherPicture1("");
                    }}
                  />
                )}
                {!otherPicture1 && (
                  <OtherImg className="admin__addGoods--coverImg" />
                )}
                {otherPicture1 && (
                  <img
                    src={otherPicture1}
                    alt="cover good"
                    className="admin__addGoods--img"
                  />
                )}
              </div>
              <div className="admin__addGoods--fileThumb dashedBorder">
                <input
                  type="file"
                  className="admin__addGoods--inputFile"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      const url = await uploadImage(e.target.files[0]);
                      setOtherPicture2(url);
                    }
                  }}
                />
                {otherPicture2 && (
                  <AdminBin
                    className="admin__image--bin"
                    onClick={() => {
                      onDeleteImageClick(otherPicture2);
                      setOtherPicture2("");
                    }}
                  />
                )}
                {!otherPicture2 && (
                  <OtherImg className="admin__addGoods--coverImg" />
                )}
                {otherPicture2 && (
                  <img
                    src={otherPicture2}
                    alt="cover good"
                    className="admin__addGoods--img"
                  />
                )}
              </div>
              <div className="admin__addGoods--fileThumb dashedBorder">
                <input
                  type="file"
                  className="admin__addGoods--inputFile"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      const url = await uploadImage(e.target.files[0]);
                      setOtherPicture3(url);
                    }
                  }}
                />
                {otherPicture3 && (
                  <AdminBin
                    className="admin__image--bin"
                    onClick={() => {
                      onDeleteImageClick(otherPicture3);
                      setOtherPicture3("");
                    }}
                  />
                )}
                {!otherPicture3 && (
                  <OtherImg className="admin__addGoods--coverImg" />
                )}
                {otherPicture3 && (
                  <img
                    src={otherPicture3}
                    alt="cover good"
                    className="admin__addGoods--img"
                  />
                )}
              </div>
              <div className="admin__addGoods--fileThumb dashedBorder">
                <input
                  type="file"
                  className="admin__addGoods--inputFile"
                  onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      const url = await uploadImage(e.target.files[0]);
                      setOtherPicture4(url);
                    }
                  }}
                />
                {otherPicture4 && (
                  <AdminBin
                    className="admin__image--bin"
                    onClick={() => {
                      onDeleteImageClick(otherPicture4);
                      setOtherPicture4("");
                    }}
                  />
                )}
                {!otherPicture4 && (
                  <OtherImg className="admin__addGoods--coverImg" />
                )}
                {otherPicture4 && (
                  <img
                    src={otherPicture4}
                    alt="cover good"
                    className="admin__addGoods--img"
                  />
                )}
              </div>
            </div>
          </>
        )}
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Additional info</p>
            <div className="admin__addGoods--inputWrapper">
              <input
                type="text"
                className={
                  labelNameError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={labelName}
                onChange={(e) => {
                  setLabelName(e.target.value);
                }}
                placeholder="Label name"
              />
              <input
                type="text"
                className={
                  issueError
                    ? "admin__addGoods--input admin__addGoods--inputError"
                    : "admin__addGoods--input"
                }
                value={issue}
                onChange={(e) => {
                  setIssue(e.target.value);
                }}
                placeholder="Issue (Year, Сolor, LPs)"
              />
            </div>
          </>
        )}
        {type !== "merch" && (
          <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
            <input
              type="text"
              className={
                countryError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
              placeholder="Country"
            />
            <input
              type="text"
              className={
                genreError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={genre}
              onChange={(e) => {
                setGenre(e.target.value);
              }}
              placeholder="Genre/Style"
            />
          </div>
        )}
        <p className="admin__addGoods--label">Price</p>
        <div
          className="admin__addGoods--input admin__addGoods--radio"
          style={{ color: showcase ? "#ffffff" : "", marginBottom: "10px" }}
          onClick={() => {
            setSale((prev) => !prev);
          }}
        >
          <div
            className={
              sale
                ? "admin__addGoods--radioSquare admin__addGoods--radioChoosen"
                : "admin__addGoods--radioSquare"
            }
          ></div>
          Sale
        </div>
        {sale && (
          <>
            <p className="admin__addGoods--label">Before sale</p>
            <div className="admin__addGoods--inputWrapper">
              <div className="admin__addGoods--priceWrapper">
                <input
                  type="text"
                  className={
                    uahSaleError
                      ? "admin__addGoods--input admin__addGoods--inputError"
                      : "admin__addGoods--input"
                  }
                  value={uahSale}
                  onChange={(e) => {
                    setUahSale(e.target.value);
                  }}
                />
                <p className="admin__addGoods--currency">UAH</p>
              </div>
              <div className="admin__addGoods--priceWrapper">
                <input
                  type="text"
                  className={
                    usdSaleError
                      ? "admin__addGoods--input admin__addGoods--inputError"
                      : "admin__addGoods--input"
                  }
                  value={usdSale}
                  onChange={(e) => {
                    setUsdSale(e.target.value);
                  }}
                />
                <p className="admin__addGoods--currency">USD</p>
              </div>
            </div>
            <p className="admin__addGoods--label">After sale</p>
          </>
        )}
        <div className="admin__addGoods--inputWrapper">
          <div className="admin__addGoods--priceWrapper">
            <input
              type="text"
              className={
                uahError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={uah}
              onChange={(e) => {
                setUah(e.target.value);
              }}
            />
            <p className="admin__addGoods--currency">UAH</p>
          </div>
          <div className="admin__addGoods--priceWrapper">
            <input
              type="text"
              className={
                usdError
                  ? "admin__addGoods--input admin__addGoods--inputError"
                  : "admin__addGoods--input"
              }
              value={usd}
              onChange={(e) => {
                setUsd(e.target.value);
              }}
            />
            <p className="admin__addGoods--currency">USD</p>
          </div>
        </div>
        {type !== "merch" && (
          <>
            <p className="admin__addGoods--label">Tracklist</p>
            <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
              <input
                type="text"
                className="admin__addGoods--input"
                value={tracklist}
                onChange={(e) => {
                  setTracklist(e.target.value);
                }}
                placeholder="Tracklist (paste list) "
              />
            </div>
          </>
        )}
        <p className="admin__addGoods--label">You might like it (if manual)</p>
        <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
          <input
            type="text"
            className="admin__addGoods--input"
            value={link1}
            onChange={(e) => {
              setLink1(e.target.value);
            }}
            placeholder="link"
          />
          <input
            type="text"
            className="admin__addGoods--input"
            value={link2}
            onChange={(e) => {
              setLink2(e.target.value);
            }}
            placeholder="link"
          />
          <input
            type="text"
            className="admin__addGoods--input"
            value={link3}
            onChange={(e) => {
              setLink3(e.target.value);
            }}
            placeholder="link"
          />
        </div>
        <p className="admin__addGoods--label">CEO tags</p>
        <div className="admin__addGoods--inputWrapper admin__addGoods--spaceT">
          <input
            type="text"
            className="admin__addGoods--input"
            value={tags}
            onChange={(e) => {
              setTags(e.target.value);
            }}
            placeholder="Tags"
          />
        </div>
        <button
          type="button"
          className="admin__addGoods--submit"
          onClick={() => {
            onSubmitButtonClick();
          }}
        >
          Додати товар
        </button>
      </div>
    </>
  );
};
