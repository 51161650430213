import { Title } from "../../components/Title/Title";
import "swiper/css";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { useEffect, useState } from "react";
import { getMerch, getUsdExchange } from "../../services/axios";
import { useParams } from "react-router-dom";
import { Merch } from "../../interfaces/merch";
import { useTranslation } from "react-i18next";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { Recommendation } from "../../components/Recommendation/Recommendation";
import { Loader } from "../../components/Loader/Loader";
import { SEO } from "../../components/SEO/SEO";
import { toast } from "react-toastify";

SwiperCore.use([Navigation, Pagination]);
export const ReleaseMerch = () => {
  const [direction, setDirection] = useState(true);
  const [data, setData] = useState<Merch | null>(null);
  const [sizesOpen, setSizesOpen] = useState(false);
  const [size, setSize] = useState("XL");
  const [colorOpen, setColorOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState("#fff");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(41);

  const { id } = useParams();
  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  useEffect(() => {
    const fetchItem = async () => {
      const itemMerch = await getMerch(id, i18n.language);
      setData(itemMerch);
    };

    fetchItem();
  }, [i18n.language, id]);

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      window.location.href = `/release/merch/${data?.otherLanguageId}`;
    }
  }, [data?.otherLanguageId, i18n.language, currentLanguage]);

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  useEffect(() => {
    if (data) {
      const currentSize = data?.info[currentIndex].xl
        ? "XL"
        : data?.info[currentIndex].l
        ? "L"
        : data?.info[currentIndex].m
        ? "M"
        : data?.info[currentIndex].s
        ? "S"
        : "XS";
      setSize(currentSize);
      setCurrentColor(data.info[currentIndex].color);
    }
  }, [data, currentIndex]);

  const addToCart = () => {
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem("userCartTypes", JSON.stringify([data?.type]));
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push(currentColor);
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([currentColor]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push(size);
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([size]));
    }
    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
    setTimeout(() => {
      window.location.href = "/cart";
    }, 500);
  };

  const onButtonBuyClick = () => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      if (ids.includes(id)) {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 500);
      } else {
        const userCartLimits: any = localStorage.getItem("userCartLimits");
        if (userCartLimits !== null) {
          if (data?.type) {
            const cartLimits = JSON.parse(userCartLimits);
            if (cartLimits[data?.type] < 5) {
              if (cartLimits[data?.type]) {
                cartLimits[data?.type] += 1;
              } else {
                cartLimits[data?.type] = 1;
              }
              localStorage.setItem(
                "userCartLimits",
                JSON.stringify(cartLimits)
              );
              ids.push(id);
              localStorage.setItem("userCartIds", JSON.stringify(ids));
              setTimeout(() => {
                window.location.href = "/cart";
              }, 500);
              addToCart();
            } else {
              toast(t("LimitsRelease") + data?.type, {
                className: "toast__error",
                bodyClassName: "toast__body",
                progressClassName: "toast__progressBar--error",
              });
            }
          }
        } else {
          if (data?.type) {
            const limits: any = {
              vinyl: 0,
              cassette: 0,
              merch: 0,
            };
            limits[data?.type] = 1;
            localStorage.setItem("userCartLimits", JSON.stringify(limits));
          }
          ids.push(id);
          localStorage.setItem("userCartIds", JSON.stringify(ids));
          setTimeout(() => {
            window.location.href = "/cart";
          }, 500);
          addToCart();
        }
      }
    } else {
      if (data?.type) {
        const limits: any = {
          vinyl: 0,
          cassette: 0,
          merch: 0,
        };
        limits[data?.type] = 1;
        localStorage.setItem("userCartLimits", JSON.stringify(limits));
      }
      setTimeout(() => {
        window.location.href = "/cart";
      }, 500);
      localStorage.setItem("userCartIds", JSON.stringify([id]));
      addToCart();
    }
  };
  return (
    <>
      <Loader />
      {data && (
        <>
          <SEO
            title={
              `${data?.name} - ${data?.from}. ` + t("ReleaseMerchPageTitleSEO")
            }
            description={
              `${t("ReleasePageDescriptionTwoSEO")} ${data?.name} - ${
                data?.from
              }. ` + t("ReleaseMerchPageDescriptionSEO")
            }
            link={`release/merch/${id}`}
            keywords={data?.tags}
            imageCard={data?.info[0].image}
          />
          <section className="release">
            <div className="container__two">
              <Title
                title={t("ReleaseTitle")}
                type={false}
                direction={direction}
                setDirection={setDirection}
              />
              <div className="release__container">
                {data && (
                  <img
                    src={data?.info[currentIndex].image}
                    alt={data?.name}
                    className="release__image"
                  />
                )}
                <div className="release__wrapper">
                  <h2 className="release__title">
                    {data?.name} - {data?.from}
                  </h2>
                  <p
                    className="release__availability"
                    style={{
                      color:
                        data?.availability === "inStock"
                          ? "#35AFA3"
                          : data?.availability === "preOrder"
                          ? "#F5CD22"
                          : "#FB4839",
                    }}
                  >
                    {data?.availability === "inStock"
                      ? t("ReleaseInStock")
                      : data?.availability === "preOrder"
                      ? t("ReleasePreOrder")
                      : t("ReleaseOutOfStock")}
                  </p>
                  {data?.info[currentIndex].oneSizeAmount ? (
                    <></>
                  ) : (
                    <div className="release__sizes--wrapper">
                      <p
                        className="release__sizesColors"
                        style={{
                          marginLeft: i18n.language === "uk" ? "0" : "9px",
                        }}
                      >
                        {t("ReleaseSize")}:
                      </p>
                      <div className="release__size--active">{size}</div>
                      <div className="release__sizes--thumb">
                        {size !== "XL" && data?.info[currentIndex].xl ? (
                          <p
                            className="release__size"
                            onClick={() => {
                              setSize("XL");
                            }}
                          >
                            XL
                          </p>
                        ) : (
                          ""
                        )}
                        {size !== "L" && data?.info[currentIndex].l ? (
                          <p
                            className="release__size"
                            onClick={() => {
                              setSize("L");
                            }}
                          >
                            L
                          </p>
                        ) : (
                          ""
                        )}
                        {size !== "M" && data?.info[currentIndex].m ? (
                          <p
                            className="release__size"
                            onClick={() => {
                              setSize("M");
                            }}
                          >
                            M
                          </p>
                        ) : (
                          ""
                        )}
                        {size !== "S" && data?.info[currentIndex].s ? (
                          <p
                            className="release__size"
                            onClick={() => {
                              setSize("S");
                            }}
                          >
                            S
                          </p>
                        ) : (
                          ""
                        )}
                        {size !== "XS" && data?.info[currentIndex].xs ? (
                          <p
                            className="release__size"
                            onClick={() => {
                              setSize("XS");
                            }}
                          >
                            XS
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  <div className="release__sizes--wrapper">
                    <p
                      className="release__sizesColors"
                      style={{
                        marginLeft: i18n.language === "uk" ? "12px" : "0",
                      }}
                    >
                      {t("ReleaseColor")}:
                    </p>
                    <div className="release__color--active">
                      <div
                        className="release__color--box"
                        style={{ background: currentColor }}
                      ></div>
                    </div>
                    <div
                      className="release__sizes--thumb"
                      style={{
                        paddingLeft:
                          data?.info && data?.info.length > 1 ? "10px" : "0",
                      }}
                    >
                      {data?.info.map(({ color }, index) =>
                        color !== currentColor ? (
                          <div
                            key={color}
                            onClick={() => {
                              setCurrentColor(color);
                              setCurrentIndex(index);
                            }}
                            className="release__color--box"
                            style={{
                              background: color,
                              cursor: "pointer",
                            }}
                          ></div>
                        ) : (
                          <div key={color} style={{ display: "none" }}></div>
                        )
                      )}
                    </div>
                  </div>
                  <p className="release__subtitle">
                    {t("ReleaseDescription")}:
                    <span
                      className="release__span"
                      style={{ color: "#C8BEBC" }}
                    >
                      {" "}
                      {data?.description}
                    </span>
                  </p>
                  <p className="release__price">
                    {i18n.language === "uk"
                      ? data?.uah !== 0
                        ? data?.uah
                        : roundToHigherOrder(exchangeRate * data.usd)
                      : data?.usd}
                    {t("Currency")}
                    {i18n.language === "uk" &&
                      (data?.uahSale !== 0 || data?.usdSale !== 0) && (
                        <span className="release__price--sale">
                          <s>
                            {data?.uahSale !== 0
                              ? data?.uahSale
                              : roundToHigherOrder(
                                  data?.uahSale * exchangeRate
                                )}{" "}
                            {t("Currency")}
                          </s>
                        </span>
                      )}
                    {i18n.language === "en" && data?.usdSale !== 0 && (
                      <span className="release__price--sale">
                        <s>
                          {data?.usdSale} {t("Currency")}
                        </s>
                      </span>
                    )}
                  </p>
                  <button
                    type="button"
                    className="release__button button__hovering"
                    onClick={() => {
                      if (data?.availability === "outOfStock") {
                        return;
                      }
                      onButtonBuyClick();
                    }}
                  >
                    {data?.availability === "inStock"
                      ? t("ButtonAvailabilityInStock")
                      : data?.availability === "preOrder"
                      ? t("ButtonAvailabilityPreOrder")
                      : t("ButtonAvailabilityOutOfStock")}
                  </button>
                </div>
              </div>
              {data?.links.length > 0 && data?.links[0] && (
                <>
                  <p className="release__recommendation">
                    {t("RecommendationTitle")}:
                  </p>
                  <Recommendation recommendationList={data?.links} />
                </>
              )}
            </div>
          </section>
        </>
      )}
    </>
  );
};
