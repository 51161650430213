import { useState } from "react";
import { ReactComponent as Conditions } from "../../assets/images/conditions.svg";
import { ReactComponent as Payment } from "../../assets/images/payment.svg";
import { Title } from "../../components/Title/Title";
import { useTranslation } from "react-i18next";
import { SEO } from "../../components/SEO/SEO";

export const Delivery = () => {
  const [direction, setDirection] = useState(true);

  const { t } = useTranslation();

  return (
    <>
      <SEO title={t("DeliveryPageTitleSEO")} noIndex />
      <section className="delivery">
        <div className="container__two">
          <Title
            title={t("DeliveryTitle")}
            type={false}
            direction={direction}
            setDirection={setDirection}
          />
          <div className="delivery__thumb">
            <div className="delivery__item">
              <div className="delivery__wrapper">
                <Conditions />
                <h2 className="delivery__title">{t("DeliveryTerms")}</h2>
              </div>
              <p className="delivery__text--wrapper">
                <p className="delivery__text">{t("DeliveryTermsFirstText")}</p>
                <p className="delivery__text">{t("DeliveryTermsSecondText")}</p>
              </p>
            </div>
            <div className="delivery__item">
              <div className="delivery__wrapper">
                <Payment />
                <h2 className="delivery__title">{t("DeliveryPayment")}</h2>
              </div>
              <div className="delivery__text--wrapper">
                <p className="delivery__text">
                  {t("DeliveryPaymentFirstText")}
                </p>
                <p className="delivery__text">
                  {t("DeliveryPaymentSecondText")}
                </p>
                <p className="delivery__text">
                  {t("DeliveryPaymentThirdText")}
                </p>
                <p className="delivery__text">
                  {t("DeliveryPaymentFourthText")}
                </p>
              </div>
            </div>
          </div>
          <div className="delivery__info">
            <h3 className="delivery__info--title">{t("DeliveryNotes")}:</h3>
            <p className="delivery__info--text">
              {t("DeliveryNotestFirstText")}
            </p>
            <p className="delivery__info--text">
              {t("DeliveryNotestSecondText")}
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
