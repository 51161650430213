import { SEO } from "../SEO/SEO";
import { ReactComponent as TopArrow } from "../../assets/images/showcaseTopArrow.svg";
import { ReactComponent as BottomArrow } from "../../assets/images/showcaseBottomArrow.svg";
import dots from "../../assets/images/dots.png";
import { useEffect, useState } from "react";
import { Tabs } from "./Tabs";
import { archiveInvoice, getAllInvoices } from "../../services/monobank";
import { ReactComponent as AdminBack } from "../../assets/images/adminBack.svg";
import { toast } from "react-toastify";

interface Invoice {
  orderId: number;
  date: string;
  totalAmount: number;
  total: number;
  status: string;
}

export const Order = () => {
  const [isMenuOpen, setIsMenuOpen] = useState("");
  const [data, setData] = useState([]);
  const [item, setItem] = useState<any>(null);
  const [idArchived, setIdArchived] = useState("");
  const [sortField, setSortField] = useState<keyof Invoice | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  useEffect(() => {
    const fetchAllInvoices = async () => {
      setData(await getAllInvoices());
    };

    fetchAllInvoices();
  }, []);

  const countTotalAmount = (
    productInfo: [{ id: string; amount: string; price: number }]
  ) => {
    let total = 0;
    for (const el of productInfo) {
      total += +el.amount;
    }
    return total;
  };

  const filterInvoicesByDate = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const temp = await getAllInvoices();
    const filteredData = temp.filter(({ date }: { date: string }) =>
      date.includes(e.target.value)
    );
    setData(filteredData);
  };

  const filterInvoicesById = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const temp = await getAllInvoices();
    if (e.target.value === "") {
      setData(temp);
      return;
    }
    const filteredData = temp.filter(
      ({ orderId }: { orderId: number }) => orderId === +e.target.value
    );
    setData(filteredData);
  };

  const clearFilter = () => {
    window.location.href = "/admin/orders";
  };

  const handleSort = (field: keyof Invoice) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);

    const sortedData = [...data].sort((a, b) => {
      if (field === "date") {
        const parseDate = (dateStr: string) => {
          const [day, month, year, hour, minute] = dateStr
            .split(/[\s.:]/)
            .map(Number);
          return new Date(year, month - 1, day, hour, minute);
        };

        const dateA = parseDate(a[field] as string);
        const dateB = parseDate(b[field] as string);

        return order === "asc"
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      } else if (field === "status") {
        const statusA = (a[field] as string).toLowerCase();
        const statusB = (b[field] as string).toLowerCase();
        if (statusA < statusB) return order === "asc" ? -1 : 1;
        if (statusA > statusB) return order === "asc" ? 1 : -1;
        return 0;
      } else {
        const valueA = a[field] as number;
        const valueB = b[field] as number;
        return order === "asc" ? valueA - valueB : valueB - valueA;
      }
    });

    setData(sortedData);
  };
  return item ? (
    <>
      <SEO title="Замовлення" noIndex />
      <div>
        <div className="order__top--info">
          <button
            className="order__back--button"
            type="button"
            onClick={() => {
              setItem(null);
            }}
          >
            <AdminBack />
          </button>
          <p className="order__title">Order {item?.orderId}</p>
        </div>
        <div className="order__top--wrapper">
          <p className="order__info--text">
            Created: <span style={{ color: "#ffffff" }}>{item?.date}</span>
          </p>
          <p className="order__info--text">
            Status:{" "}
            <span
              style={
                item?.status === "created"
                  ? { color: "#F5CD22" }
                  : item?.status === "success"
                  ? { color: "#35AFA3" }
                  : { color: "#FB4839" }
              }
            >
              {item?.status === "created" ? "In Process" : item?.status}
            </span>
          </p>
        </div>
        <div className="order__info--section">
          <div className="order__info--item">
            <p className="order__block--title">Contact</p>
            <div className="order__block--wrapper">
              <p className="order__block--subtitle">Name:</p>
              <p className="order__block--value">{item?.name}</p>
            </div>
            <div className="order__block--wrapper">
              <p className="order__block--subtitle">Phone:</p>
              <p className="order__block--value">{item?.phone}</p>
            </div>
            <div className="order__block--wrapper">
              <p className="order__block--subtitle">E-mail:</p>
              <p className="order__block--value">{item?.email}</p>
            </div>
          </div>
          <div className="order__info--item">
            <p className="order__block--title">Shipping</p>
            {item?.country && (
              <div className="order__block--wrapper">
                <p className="order__block--subtitle">Country:</p>
                <p className="order__block--value">{item?.country}</p>
              </div>
            )}
            {item?.city && (
              <div className="order__block--wrapper">
                <p className="order__block--subtitle">City:</p>
                <p className="order__block--value">{item?.city}</p>
              </div>
            )}
            {item?.branch && (
              <div className="order__block--wrapper">
                <p className="order__block--subtitle">Branch:</p>
                <p className="order__block--value">{item?.branch}</p>
              </div>
            )}
            {item?.address && (
              <div className="order__block--wrapper">
                <p className="order__block--subtitle">Address:</p>
                <p className="order__block--value">{item?.address}</p>
              </div>
            )}
            {item?.index && (
              <div className="order__block--wrapper">
                <p className="order__block--subtitle">Zip-Code:</p>
                <p className="order__block--value">{item?.index}</p>
              </div>
            )}
          </div>
          <div className="order__info--item">
            <p className="order__block--title">Payment method</p>
            <div className="order__block--wrapper">
              <p className="order__block--value">{item?.paymentMethod}</p>
            </div>
          </div>
        </div>
        <div className="order__product--section">
          <p className="order__product--amount">
            Amount ({countTotalAmount(item?.productInfo)})
          </p>
          <div className="order__product--table">
            <div className="order__product--wrapper">
              <p
                className="order__product--subtitle"
                style={{ width: "225px" }}
              >
                Product id:
              </p>
              <p
                className="order__product--subtitle"
                style={{ width: "225px" }}
              >
                Name
              </p>
              <p className="order__product--subtitle">Amount</p>
              <p className="order__product--subtitle">Price</p>
            </div>
            <div className="orders__line"></div>
            {item?.productInfo.map(
              ({
                id,
                amount,
                price,
                title,
              }: {
                id: string;
                amount: string;
                price: number;
                title: string;
              }) => (
                <div key={id}>
                  <div className="order__product--wrapper">
                    <p
                      className="order__product--text"
                      style={{ width: "225px" }}
                    >
                      {id}
                    </p>
                    <p
                      className="order__product--text"
                      style={{ width: "225px" }}
                    >
                      {title}
                    </p>
                    <p className="order__product--text">{amount}</p>
                    <p className="order__product--text">{price}</p>
                  </div>
                  <div className="orders__line"></div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <SEO title="Замовлення" noIndex />
      <Tabs />
      <div className="admin__search">
        <p className="admin__search--text">Search by:</p>
        <div className="admin__search--wrapper">
          <p className="admin__search--label">Date</p>
          <input
            type="text"
            placeholder="N/A"
            className="admin__search--input"
            onChange={filterInvoicesByDate}
          />
        </div>
        <div className="admin__search--wrapper">
          <p className="admin__search--label">Order ID</p>
          <input
            type="text"
            placeholder="N/A"
            className="admin__search--input"
            onChange={filterInvoicesById}
          />
        </div>
        <button
          type="button"
          className="admin__search--button"
          onClick={() => {
            clearFilter();
          }}
        >
          Clear
        </button>
      </div>
      <div className="orders__line"></div>
      <div className="orders__wrapper">
        <div
          className="orders__sort--thumb"
          onClick={() => handleSort("orderId")}
        >
          Order ID
          <div className="showcase__arrow--wrapper">
            <TopArrow style={{ cursor: "pointer" }} />
            <BottomArrow style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div className="orders__sort--thumb" onClick={() => handleSort("date")}>
          Date
          <div className="showcase__arrow--wrapper">
            <TopArrow style={{ cursor: "pointer" }} />
            <BottomArrow style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div
          className="orders__sort--thumb"
          onClick={() => handleSort("totalAmount")}
        >
          Amount
          <div className="showcase__arrow--wrapper">
            <TopArrow style={{ cursor: "pointer" }} />
            <BottomArrow style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div
          className="orders__sort--thumb"
          onClick={() => handleSort("total")}
        >
          Total
          <div className="showcase__arrow--wrapper">
            <TopArrow style={{ cursor: "pointer" }} />
            <BottomArrow style={{ cursor: "pointer" }} />
          </div>
        </div>
        <div
          className="orders__sort--thumb"
          onClick={() => handleSort("status")}
        >
          Status
          <div className="showcase__arrow--wrapper">
            <TopArrow style={{ cursor: "pointer" }} />
            <BottomArrow style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>
      <div className="orders__line"></div>
      {data.map((element: any) => (
        <div key={element._id} className="orders__data--wrapper">
          <div className="orders__data">
            <p className="orders__data--text" style={{ width: "45px" }}>
              {element.orderId}
            </p>
            <p
              className="orders__data--text"
              style={{
                width: "145px",
                display: "flex",
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              {element.date}
            </p>
            <p className="orders__data--text">{element.total}</p>
            <p className="orders__data--text">
              {element.total}
              {element?.lang === "uk" ? "UAH" : "USD"}
            </p>
            <p
              className="orders__data--status"
              style={
                element.status === "success"
                  ? { color: "#35AFA3", width: "90px" }
                  : element.status === "created"
                  ? { color: "#F5CD22", width: "90px" }
                  : { color: "#FB4839", width: "90px" }
              }
            >
              {element.status === "created" ? "In Process" : element.status}
            </p>
            <img
              src={dots}
              alt="dots"
              className="orders__data--dots"
              onClick={() => {
                if (isMenuOpen === element._id) {
                  setIsMenuOpen("");
                } else {
                  setIsMenuOpen(element._id);
                }
              }}
            />
            <div
              className="orders__button--wrapper"
              style={
                element._id === isMenuOpen
                  ? { display: "flex" }
                  : { display: "none" }
              }
            >
              <button
                type="button"
                className="orders__button green"
                onClick={() => {
                  setItem(element);
                }}
              >
                Details
              </button>
              <button
                type="button"
                className="orders__button yellow"
                onClick={() => {
                  setIdArchived(element._id);
                }}
              >
                Archive
              </button>
              <button
                type="button"
                className="orders__button red"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `Order ID: ${element.orderId}\nDate: ${
                      element.date
                    }\nAmount: ${countTotalAmount(
                      element.productInfo
                    )}\nTotal: ${element.total} ${
                      element?.lang === "uk" ? "UAH" : "USD"
                    }\nStatus: ${
                      element.status === "created"
                        ? "In Process"
                        : element.status
                    }`
                  );
                  toast("Скопійовано", {
                    className: "toast__success",
                    bodyClassName: "toast__body",
                    progressClassName: "toast__progressBar--success",
                  });
                }}
              >
                Copy Data
              </button>
            </div>
          </div>
          <div className="orders__line"></div>
        </div>
      ))}
      {idArchived && (
        <div className="orders__modal">
          <p className="orders__modal--title">Are you sure?</p>
          <div className="orders__modal--wrapper">
            <button
              type="button"
              className="orders__modal--button"
              style={{ color: "#35AFA3" }}
              onClick={async () => {
                await archiveInvoice(idArchived);
                setData(await getAllInvoices());
                setIdArchived("");
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="orders__modal--button"
              style={{ color: "#FB4839" }}
              onClick={() => {
                setIdArchived("");
              }}
            >
              No
            </button>
          </div>
        </div>
      )}
    </>
  );
};
