import { Link } from "react-router-dom";
import { Merch } from "../../interfaces/merch";
import { useTranslation } from "react-i18next";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { useEffect, useState } from "react";
import { getUsdExchange } from "../../services/axios";

export const MerchList = ({
  data,
  direction,
}: {
  data: Merch[] | [];
  direction: boolean;
}) => {
  const [exchangeRate, setExchangeRate] = useState(41);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  return (
    <ul
      className={
        direction
          ? "productsList__list"
          : "productsList__list productsList__list--type2"
      }
    >
      {data?.map(
        (
          { _id, info, availability, name, from, uah, uahSale, usd, usdSale },
          index
        ) => (
          <li
            key={index}
            className={
              direction
                ? "productsList__item productsList__item--type1"
                : "productsList__item productsList__item--type2"
            }
          >
            <Link to={`/release/merch/${_id}`}>
              <img
                src={info[0].image}
                alt={name}
                className="productsList__image"
              />
            </Link>
            <div className="productsList__thumb">
              <p
                className="productsList__author"
                style={
                  direction ? { textAlign: "center" } : { textAlign: "start" }
                }
              >
                {name}
              </p>
              <p
                className="productsList__name"
                style={
                  direction ? { textAlign: "center" } : { textAlign: "start" }
                }
              >
                {from}
              </p>
              <div
                className="productsList__price--wrapper"
                style={
                  direction
                    ? { justifyContent: "center" }
                    : { justifyContent: "flex-start" }
                }
              >
                {i18n.language === "uk" && uahSale !== 0 && (
                  <p
                    className="productsList__price--sale"
                    style={
                      direction
                        ? { textAlign: "center" }
                        : { textAlign: "start" }
                    }
                  >
                    <s>
                      {uahSale} {t("Currency")}
                    </s>
                  </p>
                )}
                {i18n.language === "en" && usdSale !== 0 && (
                  <p
                    className="productsList__price--sale"
                    style={
                      direction
                        ? { textAlign: "center" }
                        : { textAlign: "start" }
                    }
                  >
                    <s>
                      {usdSale} {t("Currency")}
                    </s>
                  </p>
                )}
                <p
                  className="productsList__price"
                  style={
                    direction ? { textAlign: "center" } : { textAlign: "start" }
                  }
                >
                  {i18n.language === "en"
                    ? usd
                    : uah === 0
                    ? roundToHigherOrder(usd * exchangeRate)
                    : uah}{" "}
                  {t("Currency")}
                </p>
              </div>
              <Link to={`/release/merch/${_id}`}>
                <button
                  type="button"
                  className={
                    availability === "outOfStock"
                      ? "productsList__button button__outOfStock"
                      : "productsList__button button__hovering"
                  }
                  style={direction ? {} : { margin: 0 }}
                >
                  {availability === "inStock"
                    ? t("ButtonAvailabilityInStock")
                    : availability === "preOrder"
                    ? t("ButtonAvailabilityPreOrder")
                    : t("ButtonAvailabilityOutOfStock")}
                </button>
              </Link>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
