import { useTranslation } from "react-i18next";

export const ModalTabs = ({
  activeTab,
  setActiveTab,
  setBankPayment,
}: {
  activeTab: "ukraine" | "abroad";
  setActiveTab: React.Dispatch<React.SetStateAction<"ukraine" | "abroad">>;
  setBankPayment: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();

  return (
    <div className="modal__tabs">
      <div className="modal__tabs--wrapper">
        <div
          className={`modal__tab ${activeTab === "ukraine" ? "active" : ""}`}
          onClick={() => setActiveTab("ukraine")}
        >
          {t("ModalTabUkraine")}
        </div>
        <div
          className={`modal__tab ${activeTab === "abroad" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("abroad");
            setBankPayment(true);
          }}
        >
          {t("ModalTabAbroad")}
        </div>
        <div className={`modal__tab--underline ${activeTab}`}></div>
      </div>
    </div>
  );
};
