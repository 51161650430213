import { Title } from "../../components/Title/Title";
import { ReactComponent as Telegram } from "../../assets/images/telegram.svg";
import { ReactComponent as Instagram } from "../../assets/images/instagram.svg";
import { ReactComponent as Spotify } from "../../assets/images/spotify.svg";
import { ReactComponent as AppleMusic } from "../../assets/images/appleMusic.svg";
import { ReactComponent as Deezer } from "../../assets/images/deezer.svg";
import { ReactComponent as Bandcamp } from "../../assets/images/bandcamp.svg";
import { ReactComponent as Play } from "../../assets/images/play.svg";
import { useEffect, useState } from "react";
import { Artist } from "../../interfaces/artist";
import { getArtist } from "../../services/axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Recommendation } from "../../components/Recommendation/Recommendation";
import { SEO } from "../../components/SEO/SEO";
import { Loader } from "../../components/Loader/Loader";

export const Performer = () => {
  const [direction, setDirection] = useState(true);
  const [artist, setArtist] = useState<Artist | null>(null);
  const { i18n, t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [paragraphs, setParagraphs] = useState<string[]>([]);

  const { id } = useParams();

  useEffect(() => {
    if (currentLanguage !== i18n.language) {
      window.location.href = `/performer/${artist?.otherLanguageId}`;
    }
  }, [artist?.otherLanguageId, i18n.language, currentLanguage]);
  useEffect(() => {
    const fetchArtist = async () => {
      setArtist(await getArtist(id, i18n.language));
    };

    fetchArtist();
  }, [i18n.language, id]);

  useEffect(() => {
    if (artist?.description)
      setParagraphs(artist?.description.split(/\n\s*\n/));
  }, [artist?.description]);

  return (
    <>
      <Loader />
      {artist && (
        <>
          <SEO
            title={`${artist?.artistName} ` + t("PerformerPageTitleSEO")}
            description={
              `${artist?.artistName} ` + t("PerformerPageDescriptionSEO")
            }
            link={`performer/${id}`}
            keywords={
              artist?.artistName +
              " label, лейбл, украина, україна, ukraine, music, музыка, музика"
            }
            imageCard={artist?.image}
          />
          <section className="performer">
            <div className="container__two">
              <Title
                title={t("PerformerTitle")}
                type={false}
                direction={direction}
                setDirection={setDirection}
              />
              <img
                src={artist?.image}
                alt="performer"
                className="performer__image"
              />
              <h2 className="performer__title">{artist?.artistName}</h2>
              {(artist?.telegramLink || artist?.instagramLink) && (
                <div className="performer__social">
                  {artist?.instagramLink && (
                    <a
                      href={artist?.instagramLink}
                      className="performer__social--link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Instagram className="performer__social--svg" />
                    </a>
                  )}
                  {artist?.telegramLink && (
                    <a
                      href={artist?.telegramLink}
                      className="performer__social--link"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Telegram className="performer__social--svg" />
                    </a>
                  )}
                </div>
              )}
              <>
                {paragraphs.map((paragraph, index) => (
                  <p className="performer__description" key={index}>
                    {paragraph}
                  </p>
                ))}
              </>
              <div className="performer__albums">
                {artist?.albumName.map((_, index) => (
                  <div className="performer__album" key={index}>
                    <h3 className="performer__album--title">
                      {artist.albumName[index]}
                    </h3>
                    <div className="performer__album--thumb">
                      <img
                        src={artist.albumImage[index]}
                        alt={artist.albumName[index]}
                        className="performer__album--image"
                      />
                      <ul className="performer__album--list">
                        {artist.spotifyLink[index] && (
                          <li className="performer__album--item">
                            <a
                              href={artist.spotifyLink[index]}
                              className="performer__album--social"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="performer__album--wrapper">
                                <Spotify className="performer__album--svg" />
                                Spotify
                              </div>
                              <Play className="performer__album--play" />
                            </a>
                          </li>
                        )}
                        {artist.appleMusicLink[index] && (
                          <li className="performer__album--item">
                            <a
                              href={artist.appleMusicLink[index]}
                              className="performer__album--social"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="performer__album--wrapper">
                                <AppleMusic className="performer__album--svg" />
                                Apple Music
                              </div>
                              <Play className="performer__album--play" />
                            </a>
                          </li>
                        )}
                        {artist.deezerLink[index] && (
                          <li className="performer__album--item">
                            <a
                              href={artist.deezerLink[index]}
                              className="performer__album--social"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="performer__album--wrapper">
                                <Deezer className="performer__album--svg" />
                                Deezer
                              </div>
                              <Play className="performer__album--play" />
                            </a>
                          </li>
                        )}
                        {artist.bandcampLink[index] && (
                          <li className="performer__album--item">
                            <a
                              href={artist.bandcampLink[index]}
                              className="performer__album--social"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="performer__album--wrapper">
                                <Bandcamp className="performer__album--svg" />
                                Bandcamp
                              </div>
                              <Play className="performer__album--play" />
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
              {artist?.links &&
                artist?.links.length > 0 &&
                artist?.links[0] && (
                  <>
                    <p className="performer__bottom--text">
                      {t("PerformerYouMayLike")}:
                    </p>
                    <Recommendation recommendationList={artist?.links} />
                  </>
                )}
            </div>
          </section>
        </>
      )}
    </>
  );
};
