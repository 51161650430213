import { useEffect, useState } from "react";
import { Title } from "../../components/Title/Title";
import { ProductsList } from "../../components/ProductsList/ProductsList";
import { Cassette } from "../../interfaces/cassette";
import { getAllCassettes } from "../../services/axios";
import { useTranslation } from "react-i18next";
import { Pagination } from "../../components/Pagination/Pagination";
import { SortCassette } from "../../components/Sort/SortCassette";
import { SEO } from "../../components/SEO/SEO";

export const CassetteComp = () => {
  const [direction, setDirection] = useState(true);
  const [inStock, setInStock] = useState(true);
  const [data, setData] = useState<Cassette[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchAllCassettes = async () => {
      const data = await getAllCassettes(
        i18n.language,
        inStock ? "inStock" : "all",
        currentPage,
        21
      );
      setData(data?.cassette);
      setTotalPages(data?.totalPages);
    };

    fetchAllCassettes();
  }, [i18n.language, inStock, currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <SEO
        title={t("CassettePageTitleSEO")}
        description={t("CassettePageDescriptionSEO")}
        link="cassette"
        keywords="tapes, store, cassettes, кассеты, касети, купить, придбати, buy"
        imageCard="https://dywlf.com/logo512.png"
      />
      <div className="cassette">
        <div className="container__two">
          <Title
            title={t("CassetteTitle")}
            type={true}
            direction={direction}
            setDirection={setDirection}
          />
          <SortCassette
            inStock={inStock}
            setInStock={setInStock}
            data={data}
            setData={setData}
          />
          <ProductsList data={data} direction={direction} />{" "}
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};
