export const fetchCities = async (searchQuery) => {
  try {
    const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "eb1f2017ce05d9d4284525ebd05c0e84",
        modelName: "Address",
        calledMethod: "getCities",
        methodProperties: {
          FindByString: searchQuery,
        },
      }),
    });

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBranches = async (cityRef) => {
  try {
    const response = await fetch("https://api.novaposhta.ua/v2.0/json/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        apiKey: "eb1f2017ce05d9d4284525ebd05c0e84",
        modelName: "Address",
        calledMethod: "getWarehouses",
        methodProperties: {
          CityRef: cityRef,
        },
      }),
    });

    const { data } = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
