import axios from "axios";

export const uploadImage = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.post(
      "https://dywlf.com/api/upload",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.imageUrl;
  } catch (error) {
    console.error("Error uploading the file:", error);
  }
};

export const createArtist = async (data) => {
  try {
    await axios.post("https://dywlf.com/api/artist/create", data);
    return;
  } catch (error) {
    console.log(error);
  }
};

export const createVinyl = async (data) => {
  try {
    await axios.post("https://dywlf.com/api/vinyl/create", data);
    return;
  } catch (error) {
    console.log(error);
  }
};

export const createCassette = async (data) => {
  try {
    await axios.post("https://dywlf.com/api/cassette/create", data);
    return;
  } catch (error) {
    console.log(error);
  }
};

export const createMerch = async (data) => {
  try {
    await axios.post("https://dywlf.com/api/merch/create", data);
    return;
  } catch (error) {
    console.log(error);
  }
};

export const updateArtist = async (data) => {
  try {
    await axios.patch("https://dywlf.com/api/artist/update", data);
  } catch (error) {
    console.log(error);
  }
};

export const updateVinyl = async (data) => {
  try {
    await axios.patch("https://dywlf.com/api/vinyl/update", data);
  } catch (error) {
    console.log(error);
  }
};

export const updateCassette = async (data) => {
  try {
    await axios.patch("https://dywlf.com/api/cassette/update", data);
  } catch (error) {
    console.log(error);
  }
};

export const updateMerch = async (data) => {
  try {
    await axios.patch("https://dywlf.com/api/merch/update", data);
  } catch (error) {
    console.log(error);
  }
};

export const archiveArtist = async (_id, archived) => {
  try {
    await axios.patch("https://dywlf.com/api/artist/archive", {
      _id,
      archived,
    });
  } catch (error) {
    console.log(error);
  }
};

export const archiveVinyl = async (_id, archived) => {
  try {
    await axios.patch("https://dywlf.com/api/vinyl/archive", {
      _id,
      archived,
    });
  } catch (error) {
    console.log(error);
  }
};

export const archiveCassette = async (_id, archived) => {
  try {
    await axios.patch("https://dywlf.com/api/cassette/archive", {
      _id,
      archived,
    });
  } catch (error) {
    console.log(error);
  }
};

export const archiveMerch = async (_id, archived) => {
  try {
    await axios.patch("https://dywlf.com/api/merch/archive", {
      _id,
      archived,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteArtist = async (_id) => {
  try {
    await axios.delete(`https://dywlf.com/api/artist/delete/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteVinyl = async (_id) => {
  try {
    await axios.delete(`https://dywlf.com/api/vinyl/delete/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCassette = async (_id) => {
  try {
    await axios.delete(`https://dywlf.com/api/cassette/delete/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteMerch = async (_id) => {
  try {
    await axios.delete(`https://dywlf.com/api/merch/delete/${_id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getAllArtists = async (language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/artist/getAll?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllVinyls = async (language, availability, page, limit) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/vinyl/getAll?language=${language}&availability=${availability}&page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCassettes = async (language, availability, page, limit) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/cassette/getAll?language=${language}&availability=${availability}&page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllMerchs = async (language, availability, page, limit) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/merch/getAll?language=${language}&availability=${availability}&page=${page}&limit=${limit}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllVinylsAdmin = async (language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/vinyl/getAllAdmin?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllCassettesAdmin = async (language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/cassette/getAllAdmin?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllMerchsAdmin = async (language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/merch/getAllAdmin?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getAllShowcases = async (language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/showcase/getAll?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getArtist = async (_id, language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/artist/get/${_id}?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getVinyl = async (_id, language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/vinyl/get/${_id}?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getCassette = async (_id, language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/cassette/get/${_id}?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getMerch = async (_id, language) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/merch/get/${_id}?language=${language}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserCart = async (ids, types, colors, sizes, language) => {
  try {
    const userCart = {
      ids,
      types,
      colors,
      sizes,
      language,
    };
    const { data } = await axios.post(
      "https://dywlf.com/api/cart/userCart",
      userCart
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getUsdExchange = async () => {
  try {
    const { data } = await axios.get("https://dywlf.com/api/exchangeRate/get");
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteShowcase = async (_id) => {
  try {
    const { data } = await axios.delete(
      `https://dywlf.com/api/showcase/delete/${_id}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updateShowcase = async (info) => {
  try {
    const { data } = await axios.patch(
      `https://dywlf.com/api/showcase/update`,
      info
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteImage = async (fileName) => {
  try {
    await axios.delete(`https://dywlf.com/api/delete/${fileName}`);
  } catch (error) {
    console.log(error);
  }
};

export const sendTelegramPush = async (message) => {
  try {
    await axios.post(`https://dywlf.com/api/telegram/send`, { message });
  } catch (error) {
    console.log(error);
  }
};

export const login = async () => {
  try {
    const password = localStorage.getItem("passwordToAdminPage");
    const { data } = await axios.get(
      `https://dywlf.com/api/login/try?password=${password}`
    );
    if (data === "return") {
      window.location.href = "/";
    } else {
      return data;
    }
  } catch (error) {
    console.log(error);
  }
};
