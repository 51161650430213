import { NavLink } from "react-router-dom";

export const Tabs = () => {
  return (
    <div className="admin__tabs">
      <NavLink to="/admin/shop" className="admin__tab--link">
        Shop
      </NavLink>
      <NavLink to="/admin/label" className="admin__tab--link">
        Label
      </NavLink>
      <NavLink to="/admin/orders" className="admin__tab--link">
        Orders
      </NavLink>
    </div>
  );
};
