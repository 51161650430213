import { useEffect, useState } from "react";
import {
  archiveArtist,
  deleteArtist,
  getAllArtists,
} from "../../services/axios";
import { ReactComponent as AdminEdit } from "../../assets/images/adminEdit.svg";
import { ReactComponent as AdminArchive } from "../../assets/images/adminArchive.svg";
import { ReactComponent as AdminDelete } from "../../assets/images/adminDelete.svg";
import { Artist } from "../../interfaces/artist";

interface LabelProps {
  language: string;
  setArtist: React.Dispatch<React.SetStateAction<Artist | null>>;
}

export const LabelList = ({ language, setArtist }: LabelProps) => {
  const [artists, setArtists] = useState([]);
  useEffect(() => {
    const fetchAllArtists = async () => {
      const data = await getAllArtists(language);
      setArtists(data);
    };

    fetchAllArtists();
  }, [language]);

  const refetchArtists = async () => {
    const data = await getAllArtists(language);
    setArtists(data);
  };

  return (
    <ul className="admin__artist--list">
      {artists?.map(({ _id, artistName, image, adapted, archived }, index) => (
        <li className="admin__artist--item" key={_id}>
          <img src={image} alt={artistName} className="admin__artist--image" />
          <div
            className={
              archived
                ? "admin__artist--artistNameWrapper admin__artist--artistNameWrapperArchived"
                : "admin__artist--artistNameWrapper"
            }
          >
            {!adapted && (
              <div className="admin__artist--adapted">Не адаптовано!</div>
            )}
            <p className="admin__artist--artistName">{artistName}</p>
            <p className="admin__artist--artistId">{_id}</p>
            <div className="admin__artist--action">
              <AdminEdit
                className="admin__artist--labelList"
                onClick={() => {
                  setArtist(artists[index]);
                }}
              />
              <AdminArchive
                className="admin__artist--labelList"
                onClick={() => {
                  archiveArtist(_id, !archived);
                  setTimeout(() => {
                    refetchArtists();
                  }, 500);
                }}
              />
              <AdminDelete
                className="admin__artist--labelList"
                onClick={() => {
                  deleteArtist(_id);
                  setTimeout(() => {
                    refetchArtists();
                  }, 500);
                }}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};
