import axios from "axios";

export const createInvoice = async (
  amount,
  city,
  branch,
  paymentMethod,
  products,
  total,
  name,
  phone,
  email,
  message,
  activeTab,
  deliveryTotal,
  lang
) => {
  try {
    const { data } = await axios.post(
      "https://dywlf.com/api/monobank/createInvoice",
      {
        amount,
        city,
        branch,
        paymentMethod,
        products,
        total,
        name,
        phone,
        email,
        message,
        activeTab,
        deliveryTotal,
        lang,
      }
    );
    if (paymentMethod === "Банківський переказ") {
      localStorage.setItem("invoiceId", data.invoiceId);
      localStorage.setItem("invoiceLink", data.invoiceLink);
      window.open(data.invoiceLink);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const createInvoiceEng = async (
  amount,
  country,
  abroadCity,
  address,
  zip,
  paymentMethod,
  products,
  total,
  name,
  phone,
  email,
  message,
  activeTab,
  deliveryTotal,
  lang
) => {
  try {
    const { data } = await axios.post(
      "https://dywlf.com/api/monobank/createInvoice",
      {
        amount,
        country,
        abroadCity,
        address,
        index: zip,
        paymentMethod,
        products,
        total,
        name,
        phone,
        email,
        message,
        activeTab,
        deliveryTotal,
        lang,
      }
    );
    if (paymentMethod === "Банківський переказ") {
      localStorage.setItem("invoiceId", data.invoiceId);
      localStorage.setItem("invoiceLink", data.invoiceLink);
      window.open(data.invoiceLink);
    }
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const checkStatus = async (invoiceId) => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/monobank/checkStatus?invoiceId=${invoiceId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const sendReceipt = async (invoiceId) => {
  try {
    await axios.get(
      `https://dywlf.com/api/monobank/sendReceipt?invoiceId=${invoiceId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAllInvoices = async () => {
  try {
    const { data } = await axios.get(
      `https://dywlf.com/api/monobank/getAllInvoices`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const archiveInvoice = async (_id) => {
  try {
    await axios.get(`https://dywlf.com/api/monobank/archiveInvoice?_id=${_id}`);
  } catch (error) {
    console.log(error);
  }
};
