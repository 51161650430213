import { Link } from "react-router-dom";
import { Cassette } from "../../interfaces/cassette";
import { Vinyl } from "../../interfaces/vinyl";
import { useTranslation } from "react-i18next";
import { roundToHigherOrder } from "../../utils/roundToHigherOrder";
import { useEffect, useState } from "react";
import { getUsdExchange } from "../../services/axios";
import { Loader } from "../Loader/Loader";
import { toast } from "react-toastify";

export const ProductsList = ({
  data,
  direction,
}: {
  data: Vinyl[] | Cassette[] | [];
  direction: boolean;
}) => {
  const [exchangeRate, setExchangeRate] = useState(41);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  const addToCart = (index: number) => {
    const userCartTypes = localStorage.getItem("userCartTypes");
    if (userCartTypes !== null) {
      const types = JSON.parse(userCartTypes);
      types.push(data[index]?.type);
      localStorage.setItem("userCartTypes", JSON.stringify(types));
    } else {
      localStorage.setItem(
        "userCartTypes",
        JSON.stringify([data[index]?.type])
      );
    }
    const userCartColors = localStorage.getItem("userCartColors");
    if (userCartColors !== null) {
      const colors = JSON.parse(userCartColors);
      colors.push("");
      localStorage.setItem("userCartColors", JSON.stringify(colors));
    } else {
      localStorage.setItem("userCartColors", JSON.stringify([""]));
    }
    const userCartSizes = localStorage.getItem("userCartSizes");
    if (userCartSizes !== null) {
      const sizes = JSON.parse(userCartSizes);
      sizes.push("");
      localStorage.setItem("userCartSizes", JSON.stringify(sizes));
    } else {
      localStorage.setItem("userCartSizes", JSON.stringify([""]));
    }

    const userCartQuantities = localStorage.getItem("userCartQuantities");
    if (userCartQuantities !== null) {
      const quantities = JSON.parse(userCartQuantities);
      quantities.push(1);
      localStorage.setItem("userCartQuantities", JSON.stringify(quantities));
    } else {
      localStorage.setItem("userCartQuantities", JSON.stringify([1]));
    }
  };

  const onButtonBuyClick = (_id: string, index: number) => {
    const userCartIds = localStorage.getItem("userCartIds");
    if (userCartIds !== null) {
      const ids = JSON.parse(userCartIds);
      if (ids.includes(_id)) {
        setTimeout(() => {
          window.location.href = "/cart";
        }, 500);
      } else {
        const userCartLimits: any = localStorage.getItem("userCartLimits");
        if (data[index]?.type) {
          const cartLimits = JSON.parse(userCartLimits);
          if (cartLimits[data[index]?.type] < 5) {
            if (cartLimits[data[index]?.type]) {
              cartLimits[data[index]?.type] += 1;
            } else {
              cartLimits[data[index]?.type] = 1;
            }
            localStorage.setItem("userCartLimits", JSON.stringify(cartLimits));
            ids.push(_id);
            localStorage.setItem("userCartIds", JSON.stringify(ids));
            setTimeout(() => {
              window.location.href = "/cart";
            }, 500);
            addToCart(index);
          } else {
            toast(t("LimitsRelease") + `"${data[index]?.type}"`, {
              className: "toast__error",
              bodyClassName: "toast__body",
              progressClassName: "toast__progressBar--error",
            });
          }
        }
      }
    } else {
      if (data[index]?.type) {
        const limits: any = {
          vinyl: 0,
          cassette: 0,
          merch: 0,
        };
        limits[data[index]?.type] = 1;
        localStorage.setItem("userCartLimits", JSON.stringify(limits));
      }
      localStorage.setItem("userCartIds", JSON.stringify([_id]));
      setTimeout(() => {
        window.location.href = "/cart";
      }, 500);
      addToCart(index);
    }
  };
  return (
    <>
      <Loader />
      <ul
        className={
          direction
            ? "productsList__list"
            : "productsList__list productsList__list--type2"
        }
      >
        {data?.map(
          (
            {
              _id,
              availability,
              coverPicture,
              artistName,
              albumName,
              uah,
              uahSale,
              usd,
              usdSale,
            },
            index
          ) => (
            <li
              key={index}
              className={
                direction
                  ? "productsList__item productsList__item--type1"
                  : "productsList__item productsList__item--type2"
              }
            >
              <Link to={`/release/${_id}`}>
                <img
                  src={coverPicture}
                  alt={artistName}
                  className="productsList__image"
                />
              </Link>
              <div className="productsList__thumb">
                <p
                  className="productsList__author"
                  style={
                    direction ? { textAlign: "center" } : { textAlign: "start" }
                  }
                >
                  {artistName}
                </p>
                <p
                  className="productsList__name"
                  style={
                    direction ? { textAlign: "center" } : { textAlign: "start" }
                  }
                >
                  {albumName}
                </p>
                <div
                  className="productsList__price--wrapper"
                  style={
                    direction
                      ? { justifyContent: "center" }
                      : { justifyContent: "flex-start" }
                  }
                >
                  {i18n.language === "uk" &&
                    (uahSale !== 0 || usdSale !== 0) && (
                      <p
                        className="productsList__price--sale"
                        style={
                          direction
                            ? { textAlign: "center" }
                            : { textAlign: "start" }
                        }
                      >
                        <s>
                          {uahSale === 0
                            ? usdSale &&
                              roundToHigherOrder(exchangeRate * usdSale)
                            : uahSale}
                          {t("Currency")}
                        </s>
                      </p>
                    )}
                  {i18n.language === "en" && usdSale !== 0 && (
                    <p
                      className="productsList__price--sale"
                      style={
                        direction
                          ? { textAlign: "center" }
                          : { textAlign: "start" }
                      }
                    >
                      <s>
                        {usdSale} {t("Currency")}
                      </s>
                    </p>
                  )}
                  <p
                    className="productsList__price"
                    style={
                      direction
                        ? { textAlign: "center" }
                        : { textAlign: "start" }
                    }
                  >
                    {i18n.language === "en"
                      ? usd
                      : uah === 0
                      ? roundToHigherOrder(usd * exchangeRate)
                      : uah}{" "}
                    {t("Currency")}
                  </p>
                </div>
                <button
                  type="button"
                  className={
                    availability === "outOfStock"
                      ? "productsList__button button__outOfStock"
                      : "productsList__button button__hovering"
                  }
                  onClick={() => {
                    if (availability === "outOfStock") {
                      return;
                    }
                    onButtonBuyClick(_id, index);
                  }}
                  style={direction ? {} : { margin: 0 }}
                >
                  {availability === "inStock"
                    ? t("ButtonAvailabilityInStock")
                    : availability === "preOrder"
                    ? t("ButtonAvailabilityPreOrder")
                    : t("ButtonAvailabilityOutOfStock")}{" "}
                </button>
              </div>
            </li>
          )
        )}
      </ul>
    </>
  );
};
