import { useLottie } from "lottie-react";
import loader from "../../assets/loader/pre-loader-1.json";

export const Loader = () => {
  const options = {
    animationData: loader,
    loop: true,
  };

  const { View } = useLottie(options);

  return (
    <div className="loader__bg">
      <div className="loader">{View}</div>
    </div>
  );
};
