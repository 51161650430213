import { useEffect, useState } from "react";
import {
  archiveVinyl,
  deleteVinyl,
  getAllVinylsAdmin,
} from "../../services/axios";
import { ReactComponent as AdminEdit } from "../../assets/images/adminEdit.svg";
import { ReactComponent as AdminArchive } from "../../assets/images/adminArchive.svg";
import { ReactComponent as AdminDelete } from "../../assets/images/adminDelete.svg";
import { Vinyl } from "../../interfaces/vinyl";

interface VinylProps {
  language: string;
  setVinyl: React.Dispatch<React.SetStateAction<Vinyl | null>>;
}

export const VinylList = ({ language, setVinyl }: VinylProps) => {
  const [vinyls, setVinyls] = useState([]);

  useEffect(() => {
    const fetchAllVinyls = async () => {
      const data = await getAllVinylsAdmin(language);
      setVinyls(data);
    };

    fetchAllVinyls();
  }, [language]);

  const refetchVinyls = async () => {
    const data = await getAllVinylsAdmin(language);
    setVinyls(data);
  };

  return (
    <ul className="admin__artist--list">
      {vinyls?.map(
        (
          {
            _id,
            availability,
            amount,
            artistName,
            albumName,
            coverPicture,
            archived,
            adapted,
          },
          index
        ) => (
          <li className="admin__artist--item" key={_id}>
            <img
              src={coverPicture}
              alt={artistName}
              className="admin__artist--image"
            />
            <div
              style={{
                justifyContent: "space-between",
                paddingRight: "65px",
                paddingTop: "20px",
              }}
              className={
                archived
                  ? "admin__artist--artistNameWrapper admin__artist--artistNameWrapperArchived"
                  : "admin__artist--artistNameWrapper"
              }
            >
              {!adapted && (
                <div className="admin__artist--adapted">Не адаптовано!</div>
              )}
              <div className="admin__goodsList--artistNameWrapper">
                <p className="admin__goodsList--artistName">{artistName}</p>
                <p className="admin__goodsList--albumName">{albumName}</p>
              </div>
              <div className="admin__goodsList--additional">
                <p className="admin__goodsList--id">{_id}</p>
                <p
                  className="admin__goodsList--amount"
                  style={{
                    color:
                      availability === "inStock"
                        ? "#35AFA3"
                        : availability === "preOrder"
                        ? "#F5CD22"
                        : "#FB4839",
                  }}
                >
                  {availability === "inStock"
                    ? amount
                    : availability === "preOrder"
                    ? "Pre-order"
                    : "Out of stock"}
                </p>
              </div>
              <div className="admin__artist--action">
                <AdminEdit
                  className="admin__artist--labelList"
                  onClick={() => {
                    setVinyl(vinyls[index]);
                  }}
                />
                <AdminArchive
                  className="admin__artist--labelList"
                  onClick={() => {
                    archiveVinyl(_id, !archived);
                    setTimeout(() => {
                      refetchVinyls();
                    }, 500);
                  }}
                />
                <AdminDelete
                  className="admin__artist--labelList"
                  onClick={() => {
                    deleteVinyl(_id);
                    setTimeout(() => {
                      refetchVinyls();
                    }, 500);
                  }}
                />
              </div>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
