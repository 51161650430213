import { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/images/arrow.svg";
import { useTranslation } from "react-i18next";
import { getUsdExchange } from "../../services/axios";
import { Merch } from "../../interfaces/merch";

export const SortMerch = ({
  inStock,
  setInStock,
  data,
  setData,
}: {
  inStock: boolean;
  setInStock: React.Dispatch<React.SetStateAction<boolean>>;
  data: Merch[] | [];
  setData: React.Dispatch<React.SetStateAction<Merch[] | []>>;
}) => {
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [sortField, setSortField] = useState("default");
  const [isAscending, setIsAscending] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(41);

  useEffect(() => {
    const fetchExchanceRate = async () => {
      setExchangeRate(await getUsdExchange());
    };

    fetchExchanceRate();
  }, []);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    let sortedMerchs = [...data];
    switch (sortField) {
      case "usd":
        if (i18n.language === "en") {
          sortedMerchs.sort((a, b) => a.usd - b.usd);
        } else {
          sortedMerchs.sort(
            (a, b) => a.usd * exchangeRate - b.usd * exchangeRate
          );
        }
        break;
      case "usdSale":
        sortedMerchs.sort(
          (a, b) => (a.usdSale ?? Infinity) - (b.usdSale ?? Infinity)
        );
        break;
      case "artistName":
        sortedMerchs.sort((a, b) => a.name.localeCompare(b.name));
        break;
      default:
        sortedMerchs = data;
        break;
    }
    if (!isAscending) {
      sortedMerchs.reverse();
    } else {
      if (sortField === "default") {
        sortedMerchs.reverse();
        const newMerchs = [...sortedMerchs];
        setData(newMerchs);
        newMerchs.reverse();
        const newMerchs2 = [...sortedMerchs];
        setData(newMerchs2);
      }
    }

    const newMerchs = [...sortedMerchs];
    setData(newMerchs);
  }, [sortField, isAscending]);

  const handleSortChange = (
    field: "default" | "usd" | "usdSale" | "artistName"
  ) => {
    if (sortField === field) {
      setIsAscending(!isAscending);
    } else {
      setSortField(field);
      setIsAscending(true);
    }
  };

  return (
    <div className="sort__wrapper">
      <div>
        <button
          type="button"
          className={
            inStock ? "sort__button--stock" : "sort__button--stock active"
          }
          onClick={() => {
            setInStock(true);
          }}
        >
          {t("SortOptionAvailable")}
        </button>
        <button
          type="button"
          className={
            inStock ? "sort__button--stock2 active" : "sort__button--stock2"
          }
          onClick={() => {
            setInStock(false);
          }}
        >
          {t("SortOptionAll")}
        </button>
      </div>
      <div
        id="wrapper"
        className="sort__sorting2--wrapper"
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          const target = e.target as HTMLElement;
          if (
            target?.id === "wrapper" ||
            target?.id === "wrapper-text" ||
            target?.id === "wrapper-arrow"
          ) {
            setIsSortOpen((prev) => !prev);
          }
        }}
      >
        <p className="sort__sorting2--text" id="wrapper-text">
          {t("SortMobile")}
        </p>
        <Arrow id="wrapper-arrow" />
        {isSortOpen && (
          <div className="sort__modal2">
            <div
              className="sort__select--wrapper"
              onClick={() => {
                setIsSortOpen(false);
                handleSortChange("default");
              }}
            >
              <Arrow
                style={{
                  transform:
                    sortField === "default"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
              <p className="sort__select--text">{t("SortByNovelty")}</p>
            </div>
            <div
              className="sort__select--wrapper"
              onClick={() => {
                setIsSortOpen(false);
                handleSortChange("usd");
              }}
            >
              <Arrow
                style={{
                  transform:
                    sortField === "usd"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
              <p className="sort__select--text">{t("SortByPrice")}</p>
            </div>
            <div
              className="sort__select--wrapper"
              onClick={() => {
                setIsSortOpen(false);
                handleSortChange("artistName");
              }}
            >
              <Arrow
                style={{
                  transform:
                    sortField === "artistName"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
              <p className="sort__select--text">{t("SortByAlphabet")}</p>{" "}
            </div>
            <div
              className="sort__select--wrapper"
              onClick={() => {
                setIsSortOpen(false);
                handleSortChange("usdSale");
              }}
            >
              <Arrow
                style={{
                  transform:
                    sortField === "usdSale"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
              <p className="sort__select--text">{t("SortByDiscount")}</p>{" "}
            </div>
          </div>
        )}
      </div>
      <div className="sort__sorting--wrapper">
        <button
          type="button"
          className={
            isSortOpen
              ? "sort__sorting--button sort__sorting--buttonActive"
              : "sort__sorting--button"
          }
          onClick={() => {
            setIsSortOpen((prev) => !prev);
          }}
        >
          {t("SortText")}:
        </button>
        <button
          type="button"
          className={
            isSortOpen
              ? "sort__sorting--type sort__sorting--typeActive"
              : "sort__sorting--type"
          }
          onClick={() => {
            setIsSortOpen((prev) => !prev);
          }}
        >
          {sortField === "default"
            ? `${t("SortByNovelty")}`
            : sortField === "usd"
            ? `${t("SortByPrice")}`
            : sortField === "artistName"
            ? `${t("SortByAlphabet")}`
            : `${t("SortByDiscount")}`}
        </button>
        {isSortOpen && (
          <div className="sort__modal">
            <div
              className="sort__select--wrapper"
              onClick={() => {
                handleSortChange("default");
                setIsSortOpen(false);
              }}
            >
              <p className="sort__select--text">{t("SortByNovelty")}</p>
              <Arrow
                style={{
                  transform:
                    sortField === "default"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
            </div>
            <div
              className="sort__select--wrapper"
              onClick={() => {
                handleSortChange("usd");
                setIsSortOpen(false);
              }}
            >
              <p className="sort__select--text">{t("SortByPrice")}</p>{" "}
              <Arrow
                style={{
                  transform:
                    sortField === "usd"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
            </div>
            <div
              className="sort__select--wrapper"
              onClick={() => {
                handleSortChange("artistName");
                setIsSortOpen(false);
              }}
            >
              <p className="sort__select--text">{t("SortByAlphabet")}</p>{" "}
              <Arrow
                style={{
                  transform:
                    sortField === "artistName"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
            </div>
            <div
              className="sort__select--wrapper"
              onClick={() => {
                handleSortChange("usdSale");
                setIsSortOpen(false);
              }}
            >
              <p className="sort__select--text">{t("SortByDiscount")}</p>{" "}
              <Arrow
                style={{
                  transform:
                    sortField === "usdSale"
                      ? isAscending
                        ? "rotate(180deg)"
                        : "rotate(0deg)"
                      : "rotate(0deg)",
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
