import { useTranslation } from "react-i18next";
import ukraine from "../../assets/images/ukraine.png";

export const About = () => {
  const { t } = useTranslation();

  return (
    <section className="about">
      <div className="container__two">
        <h2 className="about__title">{t("AboutTitle")}</h2>
        <p className="about__text" style={{ marginBottom: "20px" }}>
          DYWLF?! (DOYOUWANNALOFI?! Records) — {t("AboutDescriptionFirst")}
        </p>
        <p className="about__text">{t("AboutDescriptionSecond")}</p>
        <div className="about__wrapper">
          <img
            src={ukraine}
            alt={t("AboutImageUkraine")}
            className="about__image"
          />
          <div className="about__thumb">
            <p className="about__info">STAND WITH UKRAINE</p>
            <p className="about__description">{t("AboutSupportArmy")}</p>
            <a href="https://savelife.in.ua/" className="about__button">
              Donate now!
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
