import { useState } from "react";
import { ActionsLabel } from "./ActionsLabel";
import { LabelList } from "./LabelList";
import { Tabs } from "./Tabs";
import { Artist } from "../../interfaces/artist";
import { EditArtist } from "./EditArtist";
import { SEO } from "../SEO/SEO";

export const AdminLabel = () => {
  const [language, setLanguage] = useState("uk");
  const [artist, setArtist] = useState<Artist | null>(null);

  return artist ? (
    <>
      <SEO title="Редагування артиста" noIndex />
      <EditArtist artist={artist} setArtist={setArtist} language={language} />
    </>
  ) : (
    <>
      <SEO title="Адмінка" noIndex />
      <Tabs />
      <ActionsLabel language={language} setLanguage={setLanguage} />
      <LabelList language={language} setArtist={setArtist} />
    </>
  );
};
